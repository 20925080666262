var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"YlsKfSMvpGLE9EN1jE0au"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import ignoreErrors from './src/constants/ignoredErrors';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NEXT_PUBLIC_NEXT_ENV === 'production') {
  Sentry.init({
    enabled: process.env.NEXT_PUBLIC_NEXT_ENV === 'production',
    environment: process.env.NEXT_PUBLIC_NEXT_ENV, // Get environment from manually set variable instead of NODE_ENV
    dsn: SENTRY_DSN || 'https://369438fe58e24b04a6eefe33c89680e5@o257259.ingest.us.sentry.io/6062011',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.1,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps

    ignoreErrors: ignoreErrors,
  });
}