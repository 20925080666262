import { useSearchContext } from '@context/SearchContext';
import React, { useRef } from 'react';

type Props = {
  onClick: () => void;
};

export default function SelectSearchInput({ onClick }: Props) {
  const { searchValue, handleTypeAheadSearch } = useSearchContext();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }

    onClick();
  };

  return (
    <input
      ref={inputRef}
      onChange={(e) => handleTypeAheadSearch(e.target.value)}
      value={searchValue}
      onClick={handleInputClick}
      type="text"
      className="bg-transparent outline-none w-full placeholder-rp-disabled text-custom-black font-medium leading-18px truncate text-base text-left d:text-18"
      placeholder="City, State, or Hotel"
    />
  );
}
