import axios from 'axios';

/**
 * Returns the JWT token from the cookie or fetch a new one if cookie is not set.
 * It can force a token refresh by providing a second argument.
 * @param email: string
 * @return token: string | null
 */
export default async function getToken(email: string) {
  if (!email) return null;

  try {
    const response = await axios.post(
      '/api/iterable/token',
      { email },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    return response.data?.token;
  } catch (error) {
    // fail silently
  }

  return null;
}
