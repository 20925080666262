// Vincent: due to uncertainty (we still don't know if we're rolling out variant 1 or 2),
// a constant "FAIR_PRICING_VARIANT" is created to replace the amplitude experiment.
// This constant allows us to rollout either variant 1 or 2 simply by changing its value.
//
// We can do an actual clean up of the variants once we know definitively which one
// want to go with.

type FairPricingVariant = 'variant1' | 'variant2';
const FAIR_PRICING_VARIANT: FairPricingVariant = 'variant1';
const FAIR_PRICING_SERVICE_FEE_RATE = 0.0875;

export { FAIR_PRICING_VARIANT, FAIR_PRICING_SERVICE_FEE_RATE };
