import React, { ReactNode, MouseEventHandler } from 'react';

const baseStyles =
  'w-full flex justify-center items-center border-2 active:bg-rp-primary-22 pt-3.5 px-5 pb-3.5 mb-4 rounded-md text-rp-primary-black font-rp-pn-semi-bold';

type Props = {
  children: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
  classes?: string;
};

const defaultProps = {
  classes: '',
};

export default function ButtonLogin({ onClick, children, classes }: Props) {
  return (
    <button type="button" className={`${baseStyles} ${classes}`} onClick={onClick}>
      {children}
    </button>
  );
}

ButtonLogin.defaultProps = defaultProps;
