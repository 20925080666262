import React, { Dispatch, SetStateAction } from 'react';
import { useSearchContext } from '@context/SearchContext';
import locationPin from '@assets/images/location-icon.svg';
import calendarIcon from '@assets/images/calendar-icon-fill.svg';
import ButtonGray from '@components/common/ButtonGray';
import { VisibleSelector } from '@customTypes/search';

type SetVisibleSelector = Dispatch<SetStateAction<VisibleSelector>>;

type Props = {
  setVisibleSelector: SetVisibleSelector;
};

function DateButton({ setVisibleSelector }: { setVisibleSelector: SetVisibleSelector }) {
  const { formattedDate } = useSearchContext();

  return (
    <>
      <div className="text-18 leading-18px font-rp-pn-semi-bold">Date</div>

      <div className="mt-4 w-full">
        <ButtonGray text={formattedDate} onClick={() => setVisibleSelector('date')}>
          <ButtonGray.Image imageSrc={calendarIcon} alt="calendar-icon" />
        </ButtonGray>
      </div>
    </>
  );
}

function LocationButton({ setVisibleSelector }: { setVisibleSelector: SetVisibleSelector }) {
  const { searchValue } = useSearchContext();

  return (
    <>
      <div className="text-18 leading-18px font-rp-pn-semi-bold">Location</div>

      <div className="mt-4 w-full">
        <ButtonGray
          text={searchValue || 'City, State, or Hotel'}
          textClasses={!searchValue ? 'text-rp-disabled' : ''}
          onClick={() => setVisibleSelector('location')}
        >
          <ButtonGray.Image imageSrc={locationPin} alt="location-icon" />
        </ButtonGray>
      </div>
    </>
  );
}

export default function DefaultSelector({ setVisibleSelector }: Props) {
  return (
    <div className="flex flex-col text-rp-custom-black w-full">
      {/* First block */}
      <div className="flex flex-col mt-8 px-4 w-full">
        <LocationButton setVisibleSelector={setVisibleSelector} />
      </div>

      {/* Second block */}
      <div className="border-t-8 border-rp-gray-divider flex flex-col mt-6 pt-10 px-4 w-full">
        <DateButton setVisibleSelector={setVisibleSelector} />
      </div>
    </div>
  );
}
