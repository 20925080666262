import React, { forwardRef } from 'react';
import { useSearchContext } from '@context/SearchContext';
import closeButton from '@assets/images/close-with-border.svg';
import Image from 'next/image';

type Props = {
  showLocationResults: boolean;
};

const ClearButton = forwardRef((props: Props, locationRemovalRef: any) => {
  const { showLocationResults } = props;

  const { handleClearInput, searchValue } = useSearchContext();

  return showLocationResults && searchValue ? (
    <button
      type="button"
      ref={locationRemovalRef}
      onClick={handleClearInput}
      className="cursor-pointer flex flex-shrink-0 h-5 items-center justify-center relative w-5"
    >
      <Image layout="fill" src={closeButton} alt="clear-button" />
    </button>
  ) : null;
});

export default ClearButton;
