import { useState, useEffect, useCallback } from 'react';
import sessionStorageKeys from '@constants/sessionStorageKeys';

const isBrowser: boolean = ((): boolean => typeof window !== 'undefined')();

type UseStorageReturnValue = {
  getItem: (key: string) => string;
  setItem: (key: string, value: string) => boolean;
  removeItem: (key: string) => void;
};

const localStorageKeys = [
  sessionStorageKeys.ALGOLIA_TYPE_AHEAD,
  sessionStorageKeys.ALGOLIA_TRACKED_HOTEL,
  sessionStorageKeys.CART_ID,
  sessionStorageKeys.CART_DATE,
  sessionStorageKeys.CART_NUMBER_OF_ITEMS,
  sessionStorageKeys.UTM_PARAMS,
  sessionStorageKeys.SESSION_ID,
  sessionStorageKeys.ITERABLE_EMAIL,
  sessionStorageKeys.PAYMENT_METHODS,
  sessionStorageKeys.IS_AMPLITUDE_VARIANT,
  sessionStorageKeys.POST_CHECKOUT_SURVEY,
];

const storageType = 'sessionStorage';

const getItem = (key: string): string => {
  if (isBrowser) {
    if (localStorageKeys.indexOf(key) > -1) {
      return window.localStorage[key];
    }
    return window[storageType][key];
  }
  return '';
};

const setItem = (key: string, value: string): boolean => {
  if (isBrowser) {
    if (localStorageKeys.indexOf(key) > -1) {
      window.localStorage.setItem(key, value);
      return true;
    }
    window[storageType].setItem(key, value);
    return true;
  }
  return false;
};

const removeItem = (key: string): void => {
  if (localStorageKeys.indexOf(key) > -1) {
    window.localStorage.removeItem(key);
    return;
  }
  window[storageType].removeItem(key);
};

const useSessionStorage = (): UseStorageReturnValue => ({
  getItem,
  setItem,
  removeItem,
});

export function useSessionStorageByKey(key: string, defaultValue: any) {
  const [val, setVal] = useState(defaultValue);

  useEffect(() => {
    const lVal = window.sessionStorage.getItem(key);
    if (lVal) {
      setVal(JSON.parse(lVal));
    } else {
      window.sessionStorage.setItem(key, JSON.stringify(defaultValue));
      setVal(defaultValue);
    }
  }, [key, defaultValue]);

  const setSessionStorageVal = useCallback(
    (value: any) => {
      if (window) {
        window.sessionStorage.setItem(key, JSON.stringify(value));
        setVal(value);
      }
    },
    [key],
  );

  return [val, setSessionStorageVal];
}

export default useSessionStorage;
