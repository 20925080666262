import actionTypes from '@context/actionTypes';
import { store } from '@context/store';
import React, { Dispatch, forwardRef, SetStateAction, useContext } from 'react';

type Props = {
  setShow: Dispatch<SetStateAction<boolean>>;
  isSRP?: boolean;
};

const defaultProps = {
  isSRP: false,
};

const PastDateError = forwardRef(({ isSRP, setShow }: Props, ref: any) => {
  const containerAlignment = isSRP ? 'top-13 d:top-14' : 'top-210px d:top-88px';
  const globalState = useContext(store);
  const { dispatch } = globalState;

  return (
    <div
      ref={ref}
      className={`absolute bg-white rounded-md shadow-search-dropdown-message z-100 w-full ${containerAlignment}`}
    >
      <div className="flex flex-col m-6">
        <span className="font-rp-pn-semi-bold text-17 text-custom-black">
          Looks like you are searching a passed date.
        </span>

        <span className="font-rp-pn-regular mt-2 text-15 text-custom-black">
          Please enter a date in the future or try without a date and see what awaits you.
        </span>
      </div>

      <div className="border-rp-gray-divider border-t flex justify-end pr-6 w-full">
        <button
          type="button"
          onClick={() => {
            setShow(false);

            dispatch({
              type: actionTypes.OPEN_DATE_PICKER,
              payload: true,
            });
          }}
          className="font-rp-pn-semi-bold py-3 text-rp-primary-light-2 text-15 d:font-bold d:text-17"
        >
          SELECT NEW DATE {' >'}
        </button>
      </div>
    </div>
  );
});

PastDateError.defaultProps = defaultProps;

export default PastDateError;
