/* eslint-disable react/no-array-index-key */
import React, { forwardRef, useCallback } from 'react';
import { Location } from '@components/Hotels/types';
import { useSearchContext } from '@context/SearchContext';
import ResultLink from './ResultLink';

type Props = {
  onResultClick: () => void;
};

const LocationResultsList = forwardRef((props: Props, locationSelectionRef: any) => {
  const { onResultClick } = props;
  const { searchValue, searchResults } = useSearchContext();

  const boldWords = useCallback(
    (location: Location) =>
      location.name.split(new RegExp(`(${searchValue})`, 'gi')).map((part, index) => (
        <span
          key={`${location.id}-${index}`}
          className={part.toLowerCase() === searchValue?.toLowerCase() ? 'text-custom-black' : ''}
        >
          {part}
        </span>
      )),
    [searchValue],
  );

  return (
    <div ref={locationSelectionRef} className="flex flex-col gap-2">
      {searchResults.map((location, index) => (
        <ResultLink
          onResultClick={onResultClick}
          key={`${location.id}-${index}`}
          location={location}
          result={boldWords(location)}
        />
      ))}
    </div>
  );
});

export default LocationResultsList;
