/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Dispatch, SetStateAction } from 'react';
import Image from 'next/image';
import closeArrowsIcon from '../../assets/images/close-arrows.svg';
import envelopeIcon from '../../assets/images/envelope.png';

type Props = {
  setIsLoginButtonClicked: Dispatch<SetStateAction<boolean>>;
  isCheckout: boolean;
};

export default function EmailConfirmation({ setIsLoginButtonClicked, isCheckout = false }: Props) {
  return (
    <div>
      {!isCheckout ? (
        <div className="relative bg-white flex items-center w-auto pt-7 px-5 pb-9 border rounded-xl shadow-lg mx-3 my-10 d:max-w-152 d:my-8 d:mx-auto">
          <button
            type="button"
            onClick={() => setIsLoginButtonClicked && setIsLoginButtonClicked(false)}
            className="absolute top-5 right-5"
          >
            <Image src={closeArrowsIcon} alt="close-icon" />
          </button>
          <div className="w-full font-rp-pn-regular text-rp-primary-black">
            <div className="mb-4 text-center flex justify-center">
              <div className="w-16 h-16 rounded-full shadow-lg">
                <div className="relative top-2">
                  <Image src={envelopeIcon} alt="envelope-icon" />
                </div>
              </div>
            </div>
            <div className="text-2xl text-center font-rp-pn-semi-bold mb-5 d:text-3xl d:mb-4">
              Check your email
            </div>
            <div className="mb-8 text-center">
              <p className="d:inline-block">
                Please check your email for a link to reset your password.
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
