import User from '@customTypes/user';
import jwt_decode from 'jwt-decode';

const decodeJWT = (token: string) => jwt_decode(token) || null;

const buildUserInfoObject = (decodedJWT: any, token: string): User => {
  const userInfoObject = {
    available_credit: '0.0',
    avatar: {
      url: null,
      results: {
        url: null,
      },
      details: {
        url: null,
      },
    },
    birth_day: null,
    city: null,
    email: decodedJWT.data.email,
    first_name: null,
    id: decodedJWT.data.id,
    is_impersonated: false,
    last_name: null,
    payment_methods: [],
    save_cc_details: false,
    token,
  };

  return userInfoObject;
};

const doesImpersonationCookieExist = (cookieName: string) =>
  !!document.cookie.match(new RegExp(`(^| )${cookieName}=([^;]+)`));

const clearExistingCookiesBeforeAddingNewOne = (removeCookie: any) =>
  removeCookie('userInformation', { path: '/' });

const deleteUserImpersonationCookie = (name: string) => {
  document.cookie = `${name}=; Max-Age=-99999999;`;
};

export {
  decodeJWT,
  deleteUserImpersonationCookie,
  buildUserInfoObject,
  doesImpersonationCookieExist,
  clearExistingCookiesBeforeAddingNewOne,
};
