import React, { useContext } from 'react';
import { useSearchContext } from '@context/SearchContext';
import Image from 'next/image';
import { Location } from '@components/Hotels/types';
import locationTargetIcon from '@assets/images/location-target-icon.svg';
import locationPin from '@assets/images/location-icon.svg';
import stateIcon from '@assets/images/state-icon.svg';
import hotelIcon from '@assets/images/hotel-icon.svg';
import { store } from '@context/store';

type ResultProps = {
  location: Location;
  onResultClick: () => void;
  result: JSX.Element[];
};

export default function ResultLink({ location, onResultClick, result }: ResultProps) {
  const { handleOnResultSelect } = useSearchContext();
  const { state } = useContext(store);
  const { userLocation } = state;

  const cityIndicator = location.detailed_type.includes('city') && locationPin;
  const stateIndicator = location.detailed_type.includes('state') && stateIcon;
  const detectedUserLocationIcon =
    userLocation?.name === location.name ? locationTargetIcon : false;

  return (
    <div
      className="cursor-pointer flex items-center font-rp-pn-regular h-10 w-full d:px-6"
      onClick={() => {
        onResultClick();
        handleOnResultSelect(location);
      }}
      onKeyDown={() => {
        onResultClick();
        handleOnResultSelect(location);
      }}
      role="button"
      tabIndex={-1}
    >
      <div className="h-4 relative w-4">
        <Image
          src={detectedUserLocationIcon || cityIndicator || stateIndicator || hotelIcon}
          layout="fill"
          alt="Location Icon"
        />
      </div>

      <div className="ml-6 leading-18px text-rp-disabled text-base font-medium">{result}</div>
    </div>
  );
}
