import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import { decodeString } from '@helpers/utmParams';
import { isArray } from 'lodash';
import useCurrentUser from './useCurrentUser';
import useSessionStorage from './useSessionStorage';

export default function useIterableEmail() {
  const [email, setEmail] = useState('');

  const { getItem, setItem } = useSessionStorage();
  const currentUser = useCurrentUser();
  const router = useRouter();

  const sessionId = getItem(sessionStorageKeys.SESSION_ID);
  const localStorageEmail = getItem(sessionStorageKeys.ITERABLE_EMAIL);

  const setEmailAndStore = useCallback(
    (newEmail: string) => {
      setEmail(newEmail);
      setItem(sessionStorageKeys.ITERABLE_EMAIL, newEmail);
    },
    [setItem],
  );

  useEffect(() => {
    const setEmailPriority = () => {
      const userCookieEmail = currentUser.map((u) => u.email).valueOr(null);
      const urlParamEmail = router.query.email
        ? decodeString(isArray(router.query.email) ? router.query.email[0] : router.query.email)
        : null;
      const storedEmail =
        !localStorageEmail || localStorageEmail === 'undefined@placeholder.email'
          ? `${sessionId}@placeholder.email`
          : localStorageEmail;

      setEmailAndStore(userCookieEmail || urlParamEmail || storedEmail);
    };

    setEmailPriority();
  }, [currentUser, localStorageEmail, sessionId, router.query.email, setEmailAndStore]);

  return email;
}
