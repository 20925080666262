/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';
import Image from 'next/image';
import closeArrow from '@assets/images/close-black.svg';
import styles from './BottomDrawer.module.css';

type Props = {
  children: ReactNode;
  childrenClasses?: string;
  isOpen: boolean;
  isTopOffset?: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  subTitle?: string;
  title?: string;
  isBehind?: boolean;
};

const defaultProps = {
  childrenClasses: '',
  isTopOffset: false,
  setIsOpen: () => null,
  subTitle: null,
  title: undefined,
  isBehind: false,
};

export default function BottomDrawerVariant({
  children,
  childrenClasses,
  isOpen,
  isTopOffset,
  setIsOpen,
  subTitle,
  title,
  isBehind,
}: Props) {
  const closeDrawer = () => {
    if (setIsOpen) setIsOpen(false);
  };

  useEffect(() => {
    const handleBodyScroll = () => {
      // Disable body scrolling
      document.body.classList.add('no-scroll');
      document.body.style.width = '100%';
      document.body.style.top = `-${window.scrollY}px`; // Maintain scroll position
    };

    const handleBodyScrollCleanup = () => {
      // Re-enable body scrolling
      document.body.classList.remove('no-scroll');
      document.body.style.width = '';
      const scrollY = parseInt(document.body.style.top || '0', 10);
      document.body.style.top = '';
      window.scrollTo(0, -scrollY); // Restore scroll position
    };

    if (isOpen !== undefined) {
      if (isOpen === true) {
        handleBodyScroll();
      } else {
        handleBodyScrollCleanup();
      }
    }

    return () => {
      handleBodyScrollCleanup();
    };
  }, [isOpen]);

  return (
    <div>
      <div
        className={`${
          isOpen ? 'block' : `${styles.close} hidden`
        } fixed left-0 top-0 h-full w-full z-500 bg-rp-black-faded overflow-auto`}
        onClick={closeDrawer}
        onKeyDown={() => closeDrawer}
        role="button"
        tabIndex={0}
      />

      <div
        className={`${
          isOpen ? (isTopOffset ? styles.openOffset : styles.open) : styles.close
        } drawer fixed bg-white left-0 w-full rounded-t-xl h-svh`}
        style={{ zIndex: isBehind ? 9999 : 10000, maxHeight: '92%' }}
      >
        {/* Header */}
        <div className="flex items-center relative w-full">
          <button
            type="button"
            className="absolute flex left-26px h-6 items-center justify-center w-6"
            onClick={() => {
              if (setIsOpen) setIsOpen(false);
            }}
          >
            <Image src={closeArrow} width={10} height={10} alt="close bottom drawer" />
          </button>

          {title ? (
            <div className="border-b flex flex-col items-center justify-center py-4 rounded-t-xl w-full">
              <p className="font-rp-pn-semi-bold leading-19px max-w-280 text-base text-center">
                {title}
              </p>

              {subTitle && <p className="mt-1 leading-17px opacity-70 text-sm">{subTitle}</p>}
            </div>
          ) : (
            <div className="h-10 w-full mt-5" />
          )}
        </div>

        {/* Content */}
        <div
          className={`flex flex-col items-center justify-start h-screen max-h-full overflow-y-scroll w-full ${childrenClasses}`}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

BottomDrawerVariant.defaultProps = defaultProps;
