const showClosedForSeasonBanner = (reOpenDateString: string, searchedDate: Date | undefined) => {
  if (!searchedDate || !reOpenDateString) {
    return true;
  }
  if (searchedDate && reOpenDateString) {
    const reOpenDate = new Date(`${reOpenDateString}T15:00:00`);
    reOpenDate.setHours(0, 0, 0, 0);
    return searchedDate < reOpenDate;
  }
  return true;
};

export default showClosedForSeasonBanner;
