import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import appLogo from '../../assets/images/app_logo@2x.png';

export default function HeaderBanner() {
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [deferredPrompt, setDeferredPrompt] = useState<any>();

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      setShowBanner(true);
    });
  }, []);

  const triggerPrompt = () => {
    if (deferredPrompt && deferredPrompt.prompt) {
      deferredPrompt.prompt();
    }
  };

  const hideBanner = () => {
    setShowBanner(false);
  };

  return showBanner ? (
    <div className="h-20 w-full p-2 bg-rp-gray-divider">
      <div className="flex h-full">
        <button
          className="text-rp-gray opacity-50 pr-2 pl-1 cursor-pointer"
          type="button"
          onClick={hideBanner}
        >
          x
        </button>
        <div className="relative h-14 w-14">
          <Image src={appLogo} layout="fill" objectFit="contain" alt="App logo" />
        </div>
        <div className="w-4/5 flex justify-between pl-2 font-rp-pn-light d:w-full">
          <div className="flex flex-col justify-between text-xs">
            <div>
              <p>ResortPass</p>
              <p>ResortPass Inc.</p>
            </div>
            <p className="text-rp-gray opacity-90">GET - On Google Play</p>
          </div>
          <button
            className="text-blue-500 pr-2 cursor-pointer"
            type="button"
            onClick={triggerPrompt}
          >
            View
          </button>
        </div>
      </div>
    </div>
  ) : null;
}
