import Axios, { AxiosRequestConfig } from 'axios';
import getConfig from 'next/config';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

const axiosInstance = Axios.create({
  baseURL: serverRuntimeConfig.axios_base_url || publicRuntimeConfig.axios_base_url,
});

const AXIOS = {
  get: (url: string, config?: AxiosRequestConfig) =>
    axiosInstance.get(url, config).then((res) => res.data),

  post: (url: string, config?: AxiosRequestConfig) =>
    axiosInstance.post(url, config).then((res) => res.data),

  put: (url: string, config?: AxiosRequestConfig) =>
    axiosInstance.put(url, config).then((res) => res.data),

  delete: (url: string, config?: AxiosRequestConfig) =>
    axiosInstance.delete(url, config).then((res) => res.data),

  postWithHeaders: (url: string, data?: any, config?: AxiosRequestConfig) =>
    axiosInstance.post(url, data, config).then((res) => res.data),

  putWithHeaders: (url: string, data?: any, config?: AxiosRequestConfig) =>
    axiosInstance.put(url, data, config).then((res) => res.data),
};

export default AXIOS;
