/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useState } from 'react';
import RoutingPath from '@constants/routingPath';
import SwiperCarousel from '@common/SwiperCarousel/SwiperCarousel';
import HotelApi, { HotelApiProduct } from '@customTypes/hotel-api';
import Image from 'next/image';
import locationPin from '@assets/images/location-pin-orange.svg';
import { PopularHotel } from '@customTypes/popular-hotel-data';
import { MappingObject, productSortMap } from '@constants/productSortMap';
import { SELECT_HOTEL_CARD, SWIPE_HOMEPAGE_PHOTO } from '@constants/amplitudeEvents';
import AVAILABILITY from '@constants/product';
import HotelData from '@customTypes/hotel-data';
import useAmplitudePayloads from '@hooks/useAmplitudePayloads';
import { useEvents } from '@events/EventsProvider';
import Link from 'next/link';
import Product from '@customTypes/product';
import StarRating from '@components/common/Yotpo/StarRating';
import stopwatchIcon from '@assets/images/stopwatch-icon.svg';
import useHotSpot from '@hooks/useHotSpot';
import { parseISO } from 'date-fns';
import { HotSpotMin } from '@customTypes/hot-spot';
import REVIEWS_THRESHOLD_TO_BE_SHOWN from '@constants/yotpo';

type Props = {
  hotel: HotelApi | PopularHotel | HotelData;
  showLocation?: boolean;
};

const defaultProps = {
  showLocation: false,
};

type HotelDataProduct = {
  min_price: number;
  product_name: string;
  quantity: number;
  product_type_name: string;
};

export default function PopularHotelItemDetailed({ hotel, showLocation }: Props) {
  const [sortedProducts, setSortedProducts] = useState<HotelApiProduct[] | HotelDataProduct[]>([]);
  const { generateHotelPayload, generatePopularHotelPayload } = useAmplitudePayloads();
  const { track } = useEvents();

  const cityName = (hotel as PopularHotel | HotelData).city_name || (hotel as HotelApi).cityName;

  const images = hotel.image.map((image: any) => ({
    key: image.picture.details.url,
    url: image.picture.details.url,
    imgClassnames: 'w-full swiper-lazy rounded-4',
    urlShort: image.picture.url,
  }));

  const productId =
    ((hotel as HotelApi).products as unknown as Product[])?.length > 0
      ? ((hotel as HotelApi).products[0] as unknown as Product).product_id ||
        ((hotel as HotelApi).products[0] as any).id
      : undefined;

  useEffect(() => {
    if (
      'active' in hotel &&
      (hotel as HotelApi).products &&
      (hotel as HotelApi).products.length > 0
    ) {
      const availableProducts = (hotel as HotelApi).products.filter(
        (product) => product.availability === AVAILABILITY.AVAILABLE,
      );
      const unAvailableProducts = (hotel as HotelApi).products.filter(
        (product) => product.availability === AVAILABILITY.UNAVAILABLE,
      );
      availableProducts.sort(
        (a, b) =>
          productSortMap[a.name as keyof MappingObject] -
          productSortMap[b.name as keyof MappingObject],
      );
      unAvailableProducts.sort(
        (a, b) =>
          productSortMap[a.name as keyof MappingObject] -
          productSortMap[b.name as keyof MappingObject],
      );
      setSortedProducts(availableProducts.concat(unAvailableProducts).slice(0, 3));
    }

    if (
      'best_price_product' in hotel &&
      (hotel as HotelData).products &&
      (hotel as HotelData).products.length > 0
    ) {
      const availableProducts = (hotel as HotelData).products.filter(
        (product) => product.quantity > 0,
      );
      const unAvailableProducts = (hotel as HotelData).products.filter(
        (product) => product.quantity === 0,
      );
      availableProducts.sort(
        (a, b) =>
          productSortMap[a.product_type_name as keyof MappingObject] -
          productSortMap[b.product_type_name as keyof MappingObject],
      );
      unAvailableProducts.sort(
        (a, b) =>
          productSortMap[a.product_type_name as keyof MappingObject] -
          productSortMap[b.product_type_name as keyof MappingObject],
      );
      setSortedProducts(availableProducts.concat(unAvailableProducts).slice(0, 3));
    }
  }, [hotel]);

  const onHotelClick = () => {
    if ('active' in hotel) {
      const hotelPropertyGroup = generateHotelPayload(hotel as HotelApi, undefined);
      track(SELECT_HOTEL_CARD, {
        page_url: window.location.href,
        page_title: document.title,
        ...hotelPropertyGroup,
      });
    } else {
      const hotelPropertyGroup = generatePopularHotelPayload(hotel as PopularHotel);
      track(SELECT_HOTEL_CARD, {
        page_url: window.location.href,
        page_title: document.title,
        ...hotelPropertyGroup,
      });
    }
  };

  const trackOnSlideChange = () => {
    const sectionSwiped = showLocation ? 'Popular Hotels' : 'Near Hotels';
    track(SWIPE_HOMEPAGE_PHOTO, {
      section_swiped: sectionSwiped,
    });
  };

  const { getBadgeText } = useHotSpot();

  return (
    <div className="relative flex flex-column w-full d:w-auto">
      {hotel.hotSpot ? (
        <div className="absolute bg-rp-hot-spot h-full w-full flex justify-center rounded-xl z-10 -top-6">
          <span className="font-rp-pn-light mt-2 text-white font-semibold text-13 leading-14px">
            Hot Spot Savings
          </span>
        </div>
      ) : null}
      <div className="border border-rp-gray-divider pb-5 pt-3 px-3 rounded-xl relative w-full d:w-90 z-20 bg-white">
        <div className="flex-shrink-0 h-60 relative w-full">
          <SwiperCarousel
            slides={images}
            slidesPerView={1}
            carouselClass="dlp-carousel home-near-me-carousel"
            spaceBetween={1}
            isSRP
            alt={hotel.name}
            isNavigationEnabled={false}
            startingSlideIndex={1}
            onSlideChange={trackOnSlideChange}
          />
          {hotel.hotSpot ? (
            <div className="absolute bg-white right-0 z-10 bottom-30px mr-2 mb-2 rounded-xl bg-rp-hot-spot px-2.5 py-1 h-6 flex items-center gap-5px">
              <Image src={stopwatchIcon} alt="Stopwatch Icon" />
              <span className="font-rp-pn-light text-white font-semibold text-13 leading-normal">
                {hotel.hotSpot
                  ? getBadgeText(
                      parseISO(hotel.hotSpot.booking_applicable_end_date),
                      hotel.hotSpot as HotSpotMin,
                    )
                  : ''}
              </span>
            </div>
          ) : null}
        </div>

        <Link href={`${RoutingPath.DLP}/${hotel.url}`}>
          <a
            onClick={() => onHotelClick()}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === 'Space') {
                onHotelClick();
              }
            }}
            role="button"
            tabIndex={0}
          >
            {showLocation ? (
              <div className="flex items-center mb-2">
                <Image src={locationPin} alt="location" />
                <p className="ml-1.5 text-sm font-medium">{`${cityName}, ${hotel.code}`}</p>
              </div>
            ) : null}

            <div className="w-full d:h-12">
              <p className="font-rp-pn-semi-bold line-clamp-2 text-base">{hotel.name}</p>
            </div>

            {'products' in hotel &&
            !('best_price_product' in hotel) &&
            sortedProducts &&
            sortedProducts.length > 0 ? (
              <div className="flex flex-wrap gap-2 mt-4 d:h-68px">
                {(sortedProducts as HotelApiProduct[]).map((product) => (
                  <div className="bg-rp-light-gray-4 flex h-30px items-center leading-22px px-3 rounded-full text-sm">
                    <p className="capitalize font-medium">{product.name}</p>
                  </div>
                ))}
              </div>
            ) : null}

            {'products' in hotel && 'best_price_product' in hotel ? (
              <div className="flex flex-wrap gap-2 mt-4 d:h-68px d:overflow-hidden">
                {(sortedProducts as HotelDataProduct[]).map((product) => (
                  <div className="bg-rp-light-gray-4 flex h-30px items-center leading-22px px-3 rounded-full text-sm">
                    <p className="capitalize font-medium">{product.product_name}</p>
                  </div>
                ))}
              </div>
            ) : null}

            <div className="flex items-center justify-between mt-6">
              <StarRating
                productId={productId}
                hideReviewsNumber
                reviewsThreshold={REVIEWS_THRESHOLD_TO_BE_SHOWN}
              />
              <div className="text-base text-rp-primary underline cursor-pointer mb-1">
                Book Now
              </div>
            </div>
          </a>
        </Link>
      </div>
    </div>
  );
}

PopularHotelItemDetailed.defaultProps = defaultProps;
