import React, { useContext } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import errorIcon from '@assets/images/error-icon.svg';
import shadowImage from '@assets/images/shadow-image.svg';
import notificationIcon from '@assets/images/notification-icon.svg';
import { store } from '@context/store';
import actionTypes from '@context/actionTypes';
import Popup from '../Popup/Popup';
import ButtonPrimary from '../ButtonPrimary';

export default function ErrorPopup() {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const router = useRouter();

  const onButtonClick = () => {
    if (state.popupValues.redirect) {
      router.push(state.popupValues.redirect);
    }
    dispatch({
      type: actionTypes.TOGGLE_ERROR_POPUP,
      payload: {
        isOpen: false,
      },
    });
  };

  return (
    <Popup isOpen={state.popupValues.isOpen}>
      <div className="flex items-center flex-col justify-around px-10 h-full pb-10 d:px-20">
        <div className="h-5/6 flex flex-col items-center justify-center pt-0">
          <div className="relative h-24 w-24 flex justify-center items-center self-center">
            {!state.popupValues.isWarning && (
              <>
                <div className="transform h-20 w-20 absolute">
                  <Image objectFit="fill" src={shadowImage} alt="shadow-image" />
                </div>
                <div className="transform h-12 w-12 absolute rotate-180 pt-0.5">
                  <Image objectFit="fill" src={errorIcon} alt="error-icon" />
                </div>
              </>
            )}
            {state.popupValues.isWarning && (
              <div className="transform h-20 w-20 absolute pt-0.5 mb-5">
                <Image objectFit="fill" src={notificationIcon} alt="warning-icon" />
              </div>
            )}
          </div>
          <p className="text-25 font-rp-pn-regular opacity-90 pt-2 text-center d:text-28">
            {state.popupValues.message}
          </p>
        </div>
        <div className="w-20 h-1/6">
          <ButtonPrimary onClick={onButtonClick}>OK</ButtonPrimary>
        </div>
      </div>
    </Popup>
  );
}
