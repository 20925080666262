import { isNotDevEnvironment } from '@helpers/environment';

const canUseGTM = isNotDevEnvironment() && !!process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER;

declare global {
  interface Window {
    dataLayer: any;
  }
}

type GTMPayload = {
  event?: string;
  [key: string]: any;
};

const sendToGTM = (payload: GTMPayload) => {
  window.dataLayer = window.dataLayer || [];

  try {
    if (canUseGTM) {
      if (payload.event) {
        const ga4RecommendedEvents = [
          'add_to_cart',
          'begin_checkout',
          'purchase',
          'remove_from_cart',
          'view_cart',
          'view_item_list',
        ];

        // Clear previous ecommerce object
        if (ga4RecommendedEvents.includes(payload.event)) {
          window.dataLayer.push({ ecommerce: null });
        }
      }

      window.dataLayer.push(payload);
    }
  } catch (error) {
    // no error output
  }
};

export { canUseGTM, sendToGTM };
