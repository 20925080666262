export type Option<T> = {
  value?: T;
  map<U>(fn: (arg: T) => U): Option<U>;
  mapOr<U>(fn: (arg: T) => U, or: U): Option<U>;
  filter(fn: (arg: T) => boolean): Option<T>;
  toList(): Array<T>;
  valueOr(value?: any): any;
};

const Some = <T>(value: T): Option<T> => ({
  value,
  map<U>(fn: (arg: T) => U) {
    return Some<U>(fn(this.value!));
  },
  mapOr<U>(fn: (arg: T) => U) {
    return Some<U>(fn(this.value!));
  },
  filter(fn: (arg: T) => boolean) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return fn(this.value!) ? this : None();
  },
  toList() {
    return [this.value!];
  },
  valueOr() {
    return this.value;
  },
});

const None = <T>(): Option<T> => ({
  map<U>() {
    return this as unknown as Option<U>;
  },
  mapOr<U>(_: (arg: T) => U, or: U) {
    return Some<U>(or);
  },
  filter() {
    return this;
  },
  toList() {
    return [];
  },
  valueOr(or: any) {
    return or;
  },
});

export { Some, None };
