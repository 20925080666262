type RoutingPathType = {
  [key: string]: string;
};

const RoutingPath: RoutingPathType = {
  CHECKOUT_CONFIRMATION: '/products/checkout-confirmation',
  CHECKOUT_SURVEYS: '/checkout/surveys',
  BOOKING_SUCCESS: '/bookings/thankyou',
  GUEST_BILLING_DETAILS: '/users/guest-billing-detail',
  GUEST_CHECKOUT: '/users/guest-checkout',
  DLP: '/hotels',
  SRP: '/hotel-day-passes',
  CITIES: '/cities',
  RESULTS: '/[results]',
  GIFT_CARDS: '/giftcards',
  HOME_PAGE: '/',
};

export default RoutingPath;
