import React, { useContext, useMemo } from 'react';
import { SwiperSlide } from 'swiper/react';
import PopularHotelItemDetailed from '@components/PopularHotels/PopularHotelItemDetailed';
import { PopularHotel } from '@customTypes/popular-hotel-data';
import SwiperCarousel from '@components/common/SwiperCarousel/SwiperCarousel';
import { store } from '@context/store';
import {
  AMPLITUDE_EXPERIMENT_CONTROL_NAME,
  AMPLITUDE_EXPERIMENT_VARIANT_NAME,
} from '@constants/amplitudeExperiments';
import useIsMobile from '@hooks/useIsMobile';
import styles from './PopularHotels.module.css';

type Props = {
  popularHotels: PopularHotel[];
};

export default function HomePagePopularHotels({ popularHotels }: Props) {
  const {
    state: { productTilesAbTest },
  } = useContext(store);
  const isMobile = useIsMobile();
  const title = useMemo(
    () =>
      (productTilesAbTest === AMPLITUDE_EXPERIMENT_CONTROL_NAME
        ? 'Get away for the day'
        : 'Take a daycation'),
    [productTilesAbTest],
  );

  return (
    <>
      <div className="mx-auto text-3xl text-rp-primary-new pb-10 pt-10 tracking-normal px-5 text-center font-rp-pn-regular d:text-center d:px-0 d:font-rp-pn-semi-bold d:text-4xl">
        {title}{' '}
        {productTilesAbTest === AMPLITUDE_EXPERIMENT_CONTROL_NAME && (
          <p className="text-sm text-center font-rp-pn-regular tracking-normal py-5 d:text-base">
            Prices based on starting rates. Availability and pricing not guaranteed.
          </p>
        )}
      </div>

      {productTilesAbTest === AMPLITUDE_EXPERIMENT_VARIANT_NAME && isMobile ? (
        <>
          <SwiperCarousel
            slidesPerView="auto"
            carouselClass="dlp-carousel product-tiles-carousel"
            loop={false}
            isNavigationEnabled={false}
            overideSlidesPerView
          >
            {popularHotels.slice(0, 6).map((hotel) => (
              <SwiperSlide key={hotel.id} className={styles.fixedWidth}>
                <PopularHotelItemDetailed hotel={hotel} showLocation />
                <div className="mb-8" />
              </SwiperSlide>
            ))}
          </SwiperCarousel>
          <span className="text-center block font-rp-pn-light text-base w-full">
            Select a date to see availability and pricing
          </span>
        </>
      ) : (
        <div className="flex flex-wrap flex-row items-start w-full mx-auto justify-center gap-6 gap-y-12 px-4 d:px-0 d:max-w-8xl">
          {popularHotels.slice(0, 6).map((hotel) => (
            <PopularHotelItemDetailed key={hotel.id} hotel={hotel} showLocation />
          ))}
          {productTilesAbTest === AMPLITUDE_EXPERIMENT_VARIANT_NAME && (
            <span className="text-center block font-rp-pn-light text-base w-full">
              Select a date to see availability and pricing
            </span>
          )}
        </div>
      )}
    </>
  );
}
