import { sendToGTM } from './GTM';

const hotelFavoriteTappedOnSRP = (hotelID: number, hotelName: string) => {
  sendToGTM({
    event: 'srp_hotel_favorite_tapped',
    favorites: {
      hotel_name: hotelName,
      hotel_id: hotelID,
    },
  });
};

const hotelFavoriteTappedOnDLP = (hotelID: number, hotelName: string) => {
  sendToGTM({
    event: 'dlp_hotel_favorite_tapped',
    favorites: {
      hotel_name: hotelName,
      hotel_id: hotelID,
    },
  });
};

const myFavoritesTappedOnHeader = () => {
  sendToGTM({
    event: 'my_favorites_tapped',
  });
};

const myFavoritesTappedOnList = (hotelID: number, hotelName: string) => {
  sendToGTM({
    event: 'my_favorites_hotel_tapped',
    favorites: {
      hotel_name: hotelName,
      hotel_id: hotelID,
    },
  });
};

const myFavoritesCreateAccount = () => {
  sendToGTM({
    event: 'my_favorites_create_account',
  });
};

export {
  hotelFavoriteTappedOnDLP,
  hotelFavoriteTappedOnSRP,
  myFavoritesCreateAccount,
  myFavoritesTappedOnHeader,
  myFavoritesTappedOnList,
};
