/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useRef, useEffect } from 'react';
import { AMPLITUDE_EXPERIMENT_CONTROL_NAME } from '@constants/amplitudeExperiments';
import { store } from '@context/store';
import useIsMobile from '@hooks/useIsMobile';
import SearchBarMobile from '@components/SearchBar/Mobile/SearchBarMobile';
import SearchBar from '@components/SearchBar/SearchBar';

type Props = {
  isStickyHeaderVisible: boolean;
  hideVideo?: boolean;
  setShowBottomDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  showBottomDrawer: boolean;
};

const defaultProps = {
  hideVideo: false,
};
export default function HomePageVideo({
  isStickyHeaderVisible,
  hideVideo,
  setShowBottomDrawer,
  showBottomDrawer,
}: Props) {
  const isMobile = useIsMobile();
  const videoDesktop = useRef<HTMLVideoElement>(null);
  const videoMobile = useRef<HTMLVideoElement>(null);

  const {
    state: { productTilesAbTest },
  } = useContext(store);

  /**
   * IOs devices with low power mode prevents autoplay so we need to handle through javascript.
   */
  useEffect(() => {
    if (videoDesktop.current && !isMobile) videoDesktop.current.play().then();
    if (videoMobile.current && isMobile) videoMobile.current.play().then();
  }, [isMobile]);

  const headerText = {
    main: 'Take a daycation',
    sub: 'Experience the world’s best hotels for the day, no overnight stay required.',
  };

  return (
    <>
      {!hideVideo && (
        <div className="relative">
          <video
            playsInline
            autoPlay
            loop
            muted
            ref={videoDesktop}
            poster={`https://static-assets.resortpass.com/assets/home-v2/${
              productTilesAbTest === AMPLITUDE_EXPERIMENT_CONTROL_NAME
                ? 'homepage_mobile_fallback_image.jpg'
                : 'homepage-product-tiles-fallback-image-mobile.png'
            }`}
            style={{ width: '100%', height: isMobile ? '680px' : '800px', objectFit: 'cover' }}
            className="block d:hidden homepage-video"
          >
            <source
              src={`https://static-assets.resortpass.com/assets/home-v2/${
                productTilesAbTest === AMPLITUDE_EXPERIMENT_CONTROL_NAME
                  ? 'homepage_video_mobile.mp4'
                  : 'homepage-product-tiles-video-mobile.mp4'
              }`}
            />
          </video>
          <video
            playsInline
            autoPlay
            loop
            muted
            ref={videoMobile}
            poster={`https://static-assets.resortpass.com/assets/home-v2/${
              productTilesAbTest === AMPLITUDE_EXPERIMENT_CONTROL_NAME
                ? 'homepage_desktop_fallback_image.jpg'
                : 'homepage-product-tiles-fallback-image.png'
            }`}
            style={{ width: '100%', height: isMobile ? '680px' : '800px', objectFit: 'cover' }}
            className="hidden d:block homepage-video"
          >
            <source
              src={`https://static-assets.resortpass.com/assets/home-v2/${
                productTilesAbTest === AMPLITUDE_EXPERIMENT_CONTROL_NAME
                  ? 'homepage_video_desktop_new.mp4'
                  : 'homepage-product-tiles-video.mp4'
              }`}
            />
          </video>
          <div
            className="absolute flex items-end mx-auto w-full -mt-14 top-0"
            style={{ height: `${isMobile ? '715px' : '800px'}` }}
          >
            <div className="flex flex-col w-full z-300">
              {productTilesAbTest === AMPLITUDE_EXPERIMENT_CONTROL_NAME && (
                <div className="mb-6 px-5 mx-auto w-full d:pl-0 d:pr-5 d:max-w-1100">
                  <p className="text-44 font-rp-gelica-light text-white leading-tight d:leading-normal d:text-7xl">
                    {headerText.main}
                  </p>

                  <p className="text-white mb-1 font-rp-pn-semi-bold text-lg whitespace-pre-wrap mt-0 d:mb-10 d:mt-3 d:text-2xl">
                    {headerText.sub}
                  </p>
                  <div className="mt-6">
                    {isMobile ? (
                      <SearchBarMobile
                        showBottomDrawer={showBottomDrawer}
                        setShowBottomDrawer={setShowBottomDrawer}
                      />
                    ) : (
                      <SearchBar isStickyHeaderVisible={isStickyHeaderVisible} />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

HomePageVideo.defaultProps = defaultProps;
