/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-param-reassign */
/* eslint-disable @next/next/no-img-element */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import SwiperCore, { Autoplay, Navigation, Pagination, Lazy } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getCdnImageUrl } from '@utils/cloudflare';
import { SRPPhotoSwipe } from '@events/SRP';
import { photoSwipeDLP } from '@events/DLP';
import CAROUSEL_PROPERTIES from '../../../constants/SwiperCarousel';
import Slide from '../../../types/slide';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/lazy';
import brokenImagePlaceholder from '../Image/BrokenImagePlaceholder';

SwiperCore.use([Autoplay, Pagination, Navigation, Lazy]);

const { SPEED_DEFAULT, SPEED_FAST, SPACE_BETWEEN } = CAROUSEL_PROPERTIES;

type NavigationType = {
  prevRef: React.RefObject<HTMLDivElement>;
  nextRef: React.RefObject<HTMLDivElement>;
  enabled?: boolean;
};

type Props = {
  slides?: Slide[];
  slidesPerView?: number | 'auto';
  carouselClass?: string;
  spaceBetween?: number;
  alt?: string;
  isSRP?: boolean;
  handleClick?: () => void;
  startingSlideIndex?: number;
  hidePaginationDots?: boolean;
  setCurrentSlideIndex?: Dispatch<SetStateAction<number | undefined>>;
  navigation?: NavigationType;
  children?: React.ReactNode;
  loop?: boolean;
  isNavigationEnabled?: boolean;
  overideSlidesPerView?: boolean;
  onSlideChange?: () => void;
};

const defaultProps = {
  slides: [],
  slidesPerView: 1,
  carouselClass: '',
  spaceBetween: SPACE_BETWEEN,
  alt: '',
  isSRP: false,
  handleClick: () => {},
  startingSlideIndex: 0,
  hidePaginationDots: false,
  setCurrentSlideIndex: () => null,
  navigation: null,
  children: null,
  loop: true,
  isNavigationEnabled: true,
  overideSlidesPerView: false,
  onSlideChange: () => {},
};

export default function SwiperCarousel({
  slides,
  slidesPerView,
  carouselClass,
  spaceBetween,
  alt, // hotel.name
  isSRP,
  handleClick,
  startingSlideIndex,
  hidePaginationDots,
  setCurrentSlideIndex,
  navigation,
  children,
  loop,
  isNavigationEnabled,
  overideSlidesPerView,
  onSlideChange,
}: Props) {
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const [swiperRef, setSwiperRef] = useState<any>();

  const onBreakPointChange = (swiper: any, swiperParams: any) => {
    // Hard-coded condition assuming that mobile view will always have one slide to display at once
    if (swiperParams.spaceBetween !== 0) {
      setIsMobileView(false);
    } else {
      setIsMobileView(true);
    }
  };

  const handleSwipe = (swiperElem: any) => {
    if (swiperElem && setCurrentSlideIndex && slides) {
      let index = swiperElem.activeIndex === slides.length + 1 ? 1 : swiperElem.activeIndex;
      index = index === 0 ? slides.length : index;
      setCurrentSlideIndex(index);

      // Event
      if (isSRP) {
        SRPPhotoSwipe(alt || null);
      } else {
        photoSwipeDLP(alt || null);
      }
    }
  };

  useEffect(() => {
    if (swiperRef) {
      if (swiperRef.width < 850) {
        if (startingSlideIndex !== 0) {
          swiperRef.slideTo(startingSlideIndex, 1, false);
          return;
        }
        swiperRef.slideTo(0, 1, false);
      } else {
        swiperRef.slideTo(4, 1, false);
      }
    }
  }, [startingSlideIndex, swiperRef]);

  return (
    <div className={`relative mx-auto d:w-full d:max-w-full ${carouselClass}`}>
      <div className={isMobileView ? 'mobile' : 'desktop'}>
        <Swiper
          onBeforeInit={(swiper) => setSwiperRef(swiper)}
          spaceBetween={spaceBetween}
          centeredSlides
          slidesPerView={overideSlidesPerView ? slidesPerView : 1}
          noSwiping
          onRealIndexChange={handleSwipe}
          speed={SPEED_FAST}
          preventInteractionOnTransition
          loop={loop}
          navigation={
            navigation
              ? {
                  enabled: false,
                  prevEl: navigation.prevRef.current!,
                  nextEl: navigation.nextRef.current!,
                }
              : !!isNavigationEnabled
          }
          className="mySwiper"
          preloadImages={false}
          lazy={{
            loadPrevNext: true,
          }}
          pagination={
            hidePaginationDots
              ? false
              : {
                  dynamicBullets: true,
                  dynamicMainBullets: 3,
                }
          }
          breakpoints={{
            850: {
              speed: SPEED_DEFAULT,
              spaceBetween: spaceBetween ?? SPACE_BETWEEN,
              preventInteractionOnTransition: true,
              slidesPerView,
              preloadImages: false,
              pagination: false,
              lazy: {
                loadPrevNext: true,
              },
            },
          }}
          onBreakpoint={
            (swiper: any, swiperParams: any) => onBreakPointChange(swiper, swiperParams)
            // eslint-disable-next-line react/jsx-curly-newline
          }
          onTouchEnd={onSlideChange}
        >
          {slides?.length
            ? slides.map((slide: Slide) => (
              <div className="relative" key={slide.key}>
                <div className="relative overflow-hidden">
                  <SwiperSlide key={slide.key} onClick={handleClick}>
                    {isSRP ? (
                      <div
                        className="relative bg-cover bg-center block max-w-full h-210 rounded-lg overflow-hidden swiper-lazy carousel-images"
                        data-background={getCdnImageUrl(500, `${slide.url}`, '', 95)}
                      />
                      ) : (
                        <img
                          data-src={getCdnImageUrl(500, `${slide.url}`, '', 95)}
                          alt={alt ?? 'hotel'}
                          className={slide.imgClassnames}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = brokenImagePlaceholder;
                            currentTarget.classList.add('error-image');
                          }}
                        />
                      )}
                  </SwiperSlide>
                </div>
              </div>
              ))
            : children}
        </Swiper>
      </div>
    </div>
  );
}

SwiperCarousel.defaultProps = defaultProps;
