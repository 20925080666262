import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

function useIsStickyHeaderVisible() {
  const [isStickyHeaderVisible, setIsStickyHeaderVisible] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const handleScroll = () => {
      setIsStickyHeaderVisible(window.pageYOffset > 760);
    };

    const checkStickyHeader = () => {
      if (router.pathname === '/') {
        // Initially check if we're already scrolled past the threshold
        setIsStickyHeaderVisible(window.pageYOffset > 760);
        window.addEventListener('scroll', handleScroll);
      } else {
        // For other paths, always show sticky header
        setIsStickyHeaderVisible(true);
      }
    };

    // Call the function to set initial state based on current pathname
    checkStickyHeader();

    const handleRouteChange = () => {
      checkStickyHeader();
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    // Clean up event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.pathname, router.events]);

  return isStickyHeaderVisible;
}

export default useIsStickyHeaderVisible;
