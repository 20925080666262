import User from '@customTypes/user';

import { getDateWithDashes, getDateWithSlashes } from '@helpers/dateFormatter';
import SHA256 from '@helpers/encoders';

import { sendToGTM } from './GTM';

const srpAlternateSearchResultClicked = (): void => {
  sendToGTM({
    event: 'alternate_search_result_clicked',
  });
};

const SRPHotelCardClick = (hotelName: string) => {
  sendToGTM({
    event: 'srp_hotel_card_clicked',
    srp: {
      hotel_name: hotelName,
    },
  });
};

const srpPageViewed = async (
  date: string | undefined,
  user: User,
  sessionID: string,
  location: any | null,
  country: string | undefined,
  state: string | undefined,
  city: string | undefined,
) => {
  const email = user?.email ? await SHA256(user?.email.toLowerCase().trim()) : null;

  let countryCode = country;
  if (
    country === 'United States of America' ||
    country === 'united_states_of_america' ||
    (location && location.parent_id === 'united_states_of_america')
  ) {
    countryCode = 'US';
  }

  sendToGTM({
    event: 'srp_page_view',
    sojern_srp_page_viewed: {
      vd1: date ? getDateWithDashes(new Date(date)) : null,
      vd2: date ? getDateWithDashes(new Date(date)) : null,
      vf1: city || null,
      vs1: state || null,
      vn1: countryCode || null,
      t: null,
      sha256_eml: email,
      ccid: user ? user.id?.toString() : sessionID,
    },
  });
};

const SRPPhotoSwipe = (hotelName: string | null) => {
  sendToGTM({
    event: 'srp_photos_swiped',
    srp: {
      hotel_name: hotelName,
    },
  });
};

const srpDateChange = (date: Date) => {
  sendToGTM({
    event: 'srp_date_entered',
    selected_date: date ? getDateWithSlashes(date) : null,
  });
};

const srpSearch = (searchTerm: string) => {
  // Custom event
  sendToGTM({
    event: 'srp_search_entered',
    srp: {
      search_location: searchTerm,
    },
  });
};

const srpShowOnlyAvailableFilterApplied = (): void => {
  sendToGTM({
    event: 'srp_show_only_available_filter_applied',
  });
};

const srpHighlightedSearchResultClicked = (): void => {
  sendToGTM({
    event: 'highlighted_search_result_clicked',
  });
};

const srpLaborDayBannerDateClicked = (): void => {
  sendToGTM({
    event: 'labor_day_banner_date_selected',
  });
};

const srpBadgeHotelCardClicked = (): void => {
  sendToGTM({
    event: 'badge_srp_hotel_card_clicked',
  });
};

export {
  srpAlternateSearchResultClicked,
  srpDateChange,
  srpHighlightedSearchResultClicked,
  SRPHotelCardClick,
  srpLaborDayBannerDateClicked,
  srpPageViewed,
  SRPPhotoSwipe,
  srpSearch,
  srpShowOnlyAvailableFilterApplied,
  srpBadgeHotelCardClicked,
};
