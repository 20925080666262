import React from 'react';

export default function Spinner() {
  return (
    <div className="smart-calendar-spinner-wrapper h-full fixed z-200">
      <div className="smart-calendar-spinner">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}
