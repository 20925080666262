import React, {
  useContext,
  useEffect,
  useMemo,
  useCallback,
  createContext,
  PropsWithChildren,
} from 'react';
import { store } from '@context/store';
import actionTypes from '@context/actionTypes';
import useLocalStorage from '@hooks/useLocalStorage';
import { useSessionStorageByKey } from '@hooks/useSessionStorage';

type ConsentContextType = {
  allowCookies: boolean;
  showCookiesConsentBanner: boolean;
  handleCookieConsentUpdate: (b: boolean) => void;
  onAcceptCookiesButtonClick: () => void;
  onRejectCookiesButtonClick: () => void;
};

const ConsentContext = createContext<ConsentContextType>({
  allowCookies: false,
  showCookiesConsentBanner: false,
  handleCookieConsentUpdate: () => {},
  onAcceptCookiesButtonClick: () => {},
  onRejectCookiesButtonClick: () => {},
});

export function Provider({ children }: PropsWithChildren) {
  const { dispatch, state } = useContext(store);
  const { showCookiesConsentBanner } = state;

  const [hasShownCookiesConsentPrompt, setHasShownCookiesConsentPrompt] = useSessionStorageByKey(
    'cookiesConsent',
    false,
  );

  const [allowCookies, setAllowCookies] = useLocalStorage('allowCookies', false);

  useEffect(() => {
    dispatch({
      type: actionTypes.UPDATE_ACCEPT_COOKIES,
      payload: allowCookies,
    });

    dispatch({
      type: actionTypes.UPDATE_SHOW_COOKIE_CONSENT_BANNER,
      payload: !allowCookies && !hasShownCookiesConsentPrompt,
    });
  }, [dispatch, allowCookies, hasShownCookiesConsentPrompt]);

  /**
   * Handles the update of the cookie consent.
   *
   * @param userFromUSA - boolean that specifies whether the user is from the US
   * @return {void} This function does not return a value.
   */
  const handleCookieConsentUpdate = useCallback(
    (userFromUSA: boolean) => {
      // auto set cookies / consent to cookies if the user is from US
      if (userFromUSA) {
        setAllowCookies(true);
        setHasShownCookiesConsentPrompt(true);
      }
    },
    [setAllowCookies, setHasShownCookiesConsentPrompt],
  );

  /**
   * Handles the click event of the consent button.
   *
   * @param {boolean} value - The value indicating whether the consent is given or not.
   */
  const handleConsentButtonClick = useCallback(
    (value: boolean) => {
      setAllowCookies(value);
      setHasShownCookiesConsentPrompt(true);
    },
    [setAllowCookies, setHasShownCookiesConsentPrompt],
  );

  const onAcceptCookiesButtonClick = useCallback(() => {
    handleConsentButtonClick(true);
  }, [handleConsentButtonClick]);

  const onRejectCookiesButtonClick = useCallback(() => {
    handleConsentButtonClick(false);
  }, [handleConsentButtonClick]);

  const contextValue = useMemo(
    () => ({
      allowCookies,
      showCookiesConsentBanner,
      onAcceptCookiesButtonClick,
      onRejectCookiesButtonClick,
      handleCookieConsentUpdate,
    }),
    [
      allowCookies,
      showCookiesConsentBanner,
      onAcceptCookiesButtonClick,
      onRejectCookiesButtonClick,
      handleCookieConsentUpdate,
    ],
  );

  return <ConsentContext.Provider value={contextValue}>{children}</ConsentContext.Provider>;
}

export default function useCookieConsent() {
  return useContext(ConsentContext);
}
