import User from '@customTypes/user';

const setJwtTokenHeader = (user: User) => {
  const token = `Bearer ${user.token}`;
  const headers = {
    JWT_AUTHORIZATION: token,
  };
  return headers;
};

export default setJwtTokenHeader;
