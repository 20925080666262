import React from 'react';
import useCookieConsent from '@hooks/useCookieConsent';

export default function CookiesBanner() {
  const { onAcceptCookiesButtonClick, onRejectCookiesButtonClick } = useCookieConsent();

  return (
    <div className="bg-transparent bottom-3 fixed w-full px-3 cookie-banner d:h-32">
      <div className="bg-rp-primary-light-2 mx-auto h-full shadow-cookiesBanner rounded-2xl flex flex-col justify-between p-6 d:py-5">
        <div className="w-full">
          <p className="font-rp-pn-regular leading-23px text-base text-center text-white">
            We use cookies to provide you with a better service. Visit our{' '}
            <a
              className="font-rp-pn-semi-bold underline"
              href="/privacy-policy"
              title="Open in a new window"
              target="_blank"
            >
              Privacy Policy
            </a>{' '}
            to learn more.
          </p>
        </div>

        <div className="flex flex-col items-center justify-center ml-0 mt-6 text-15 w-full d:flex-row d:mt-5 d:space-x-20">
          <div className="h-11 px-0 flex items-center w-full mb-3 d:w-auto d:mb-0">
            <button
              onClick={onAcceptCookiesButtonClick}
              className="active:scale-100 active:text-15 bg-white duration-150 ease-out flex font-rp-pn-semi-bold h-full hover:scale-105 items-center justify-center px-7 rounded-md hover:shadow-white-button-hover-shadow text-rp-primary-dark tracking-tight transform transition-transform w-full"
              type="button"
            >
              Accept All Cookies
            </button>
          </div>

          <div className="h-11 px-0 w-full d:w-auto">
            <button
              onClick={onRejectCookiesButtonClick}
              type="button"
              className="active:scale-100 active:text-15 bg-white duration-150 ease-out flex font-rp-pn-semi-bold h-full hover:scale-105 items-center justify-center px-7 rounded-md hover:shadow-white-button-hover-shadow text-rp-primary-dark tracking-tight transform transition-transform w-full"
            >
              Reject All Cookies
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
