import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export default function SearchIcon({ children }: Props) {
  return (
    <>
      <div className="ml-0 -mt-1.5 d:mt-0">
        <div className="d:w-3 d:h-20" />
      </div>
      <div className="ml-0 w-2/3 -mt-1.5 d:mt-0">{children}</div>
    </>
  );
}
