import { Some, None, Option } from '@utils/option';

export type PasswordError = {
  type: 'length' | 'alphanumeric' | 'special' | 'mismatch';
  status: 'pass' | 'fail';
  message: string;
};

type PasswordChecker = {
  checker: (password: string) => boolean;
  error: PasswordError;
};

const checkPwdLength = (password: string) => {
  if (password.length > 7) {
    return true;
  }
  return false;
};

const checkPwdForLettersAndNumbers = (password: string) => {
  if (password.match(/[a-zA-Z]/) && password.match(/\d/)) {
    return true;
  }
  return false;
};

const checkPwdForSpecialCharacters = (password: string) => {
  if (password.match(/[-+_!@#$%^&*.,?]/)) {
    return true;
  }
  return false;
};

const checkIfStringIsValidEmail = (email: string) => {
  const regexExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi;
  if (email.match(regexExp)) {
    return true;
  }
  return false;
};

export function checkPasswordRequirements(password: string): Option<Array<PasswordError>> {
  const rules: Array<PasswordChecker> = [
    {
      checker: checkPwdLength,
      error: { type: 'length', message: 'Must be at least 8 characters', status: 'fail' },
    },
    {
      checker: checkPwdForLettersAndNumbers,
      error: { type: 'alphanumeric', message: 'Must combine letters and numbers', status: 'fail' },
    },
    {
      checker: checkPwdForSpecialCharacters,
      error: {
        type: 'special',
        message: 'Must contain at least one special character',
        status: 'fail',
      },
    },
  ];

  const res = rules.map((rule) => {
    const { checker, error } = rule;
    if (checker(password)) {
      error.status = 'pass';
    }
    return error;
  });

  if (res.every((error) => error.status === 'pass')) return None();
  return Some(res);
}

export function checkMatchingPassword(
  password: string,
  confirmation: string,
): Option<PasswordError> {
  if (password !== confirmation) {
    return Some<PasswordError>({
      type: 'mismatch',
      message: 'The passwords do not match. Try again',
      status: 'fail',
    });
  }
  return None();
}

export {
  checkPwdLength,
  checkPwdForLettersAndNumbers,
  checkPwdForSpecialCharacters,
  checkIfStringIsValidEmail,
};
