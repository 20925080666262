import { sendToGTM } from './GTM';

const bookingPageView = () => {
  sendToGTM({
    event: 'booking_details_viewed',
  });
};

const cancelBookingClick = () => {
  sendToGTM({
    event: 'cancel_booking_clicked',
  });
};

const editBookingGuestClick = () => {
  sendToGTM({
    event: 'edit_guest_names_clicked',
  });
};

const bookingConfirmationSetupAccount = () => {
  sendToGTM({
    event: 'booking_completed_setup_account',
  });
};

const bookingInvitationSetupAccount = () => {
  sendToGTM({
    event: 'booking_invitation_setup_account',
  });
};

const bookingConfirmationViewCancellationPolicy = () => {
  sendToGTM({
    event: 'booking_completed_view_cancellation_policy',
  });
};

export {
  bookingConfirmationSetupAccount,
  bookingConfirmationViewCancellationPolicy,
  bookingPageView,
  cancelBookingClick,
  editBookingGuestClick,
  bookingInvitationSetupAccount,
};
