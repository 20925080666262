import React from 'react';
import Image from 'next/image';
import ButtonPrimary from '@components/common/ButtonPrimary';
import { useSearchContext } from '@context/SearchContext';
import searchIcon from '@assets/images/search-icon-bold.svg';
import SearchDrawer from './SearchDrawer';

type Props = {
  setShowBottomDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  showBottomDrawer: boolean;
};

export default function SearchBarMobile({ setShowBottomDrawer, showBottomDrawer }: Props) {
  const { formattedDate, handleSearchClick, searchValue } = useSearchContext();

  return (
    <>
      <div className="bg-white border border-rp-gray-divider rounded-xl flex h-52px items-center justify-between py-5px pr-6px pl-4 text-custom-black w-full overflow-hidden ">
        <div
          tabIndex={0}
          role="button"
          onClick={() => setShowBottomDrawer(true)}
          onKeyDown={() => setShowBottomDrawer(true)}
          className="flex flex-grow items-center justify-center space-x-4 overflow-x-hidden w-0 mr-2"
        >
          <div className="flex flex-shrink-0 h-4.5 relative w-4.5">
            <Image src={searchIcon} alt="search-icon" layout="fill" />
          </div>

          <div className="flex flex-col justify-center flex-grow overflow-hidden">
            <div className="text-sm font-rp-pn-semi-bold leading-4 truncate">
              {searchValue || 'City, State, or Hotel'}
            </div>

            <div className="text-rp-disabled text-13 leading-4 font-medium truncate">
              {`on ${formattedDate}`}
            </div>
          </div>
        </div>

        <div className="flex flex-shrink-0">
          <ButtonPrimary classes="h-42px w-95px" onClick={handleSearchClick}>
            Search
          </ButtonPrimary>
        </div>
      </div>

      {showBottomDrawer ? <SearchDrawer setShowBottomDrawer={setShowBottomDrawer} /> : null}
    </>
  );
}
