import actionTypes from '@context/actionTypes';
import { UserAction } from '@customTypes/context';

const toggleErrorPopup = (
  error: any,
  dispatch: React.Dispatch<UserAction>,
  isWarning?: boolean,
  isOpen?: boolean,
  redirect?: string | undefined,
) => {
  let message = error;
  if (error.response) {
    const { data } = error.response;
    message = data.message;
  }
  dispatch({
    type: actionTypes.TOGGLE_ERROR_POPUP,
    payload: {
      message,
      isOpen: isOpen ?? true,
      isWarning: isWarning ?? false,
      redirect: redirect ?? undefined,
    },
  });
};

export default toggleErrorPopup;
