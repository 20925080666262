import User from '@customTypes/user';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import { Some, None, Option } from '@utils/option';
import { useMemo } from 'react';
import { useCookies } from 'react-cookie';

export default function useCurrentUser() {
  const [cookies] = useCookies(['userInformation']);
  const user = useMemo<Option<User>>(() => {
    if (
      cookies?.userInformation &&
      cookies.userInformation !== 'null' &&
      cookies.userInformation?.id
    ) {
      const cUser = cookies.userInformation as User;
      if (cUser.save_cc_details) {
        try {
          const pm = window.localStorage.getItem(sessionStorageKeys.PAYMENT_METHODS);
          cUser.payment_methods = pm ? JSON.parse(pm) : [];
        } catch (_) {
          // assume no payment method if not parsable
        }
      }

      return Some(cUser);
    }
    return None();
  }, [cookies]);

  return user;
}
