const headerHasH1 = (route: string) => {
  const routes = [
    '/',
    '/users/bookings',
    '/bookings/[id]',
    '/feed_backs/new',
    '/your-privacy-rights',
  ];
  if (routes.includes(route)) {
    return true;
  }
  return false;
};

export default headerHasH1;
