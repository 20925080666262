const isAValidName = (name: string) =>
  /^[a-zA-Z\u00C0-\u017F'’&,.!-]+(?: [a-zA-Z\u00C0-\u017F'’&,.!-]+)*$/.test(name);

const isAValidURLPath = (urlParams: string) => {
  const pattern = /^\/[\w-]+(?:\/[\w-]+)*(?:\?.*)?$/;

  return pattern.test(urlParams);
};

export { isAValidName, isAValidURLPath };
