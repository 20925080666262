import { getUpdatedUser } from '@utils/services';
import User from '@customTypes/user';

const updateLocalUserInformation = async (
  user: User,
  impersonationToken: string | undefined,
  setCookie: any,
) => {
  try {
    const userSession: User = await getUpdatedUser(user.token, impersonationToken);
    const updatedUser: User = { ...user, ...userSession };
    const newCookie = {
      ...updatedUser,
      token: user.token,
    };
    newCookie.payment_methods = [];
    setCookie('userInformation', newCookie, { path: '/' });
    return newCookie;
  } catch (e) {
    setCookie('userInformation', null, { path: '/' });
    return e;
  }
};

export default updateLocalUserInformation;
