import React from 'react';

export default function HotelCardsLoadingSkeleton() {
  return (
    <div className="flex flex-wrap flex-row items-start w-full mx-auto justify-center gap-6 px-4 relative d:px-0 d:max-w-8xl">
      {/* Loading skeleton */}
      <div className="border border-rp-gray-divider mb-5 rounded-xl min-h-450 relative w-full d:w-90">
        <div className="bg-rp-light-gray-2 h-60 rounded-md px-3 pt-3" />
        <div className="bg-rp-light-gray-2 h-5 rounded-md mt-3 w-3/4 ml-3" />
        <div className="flex flex-wrap items-center text-sm py-2 ml-3">
          <div className="bg-rp-light-gray-2 h-5 w-28 px-4 mr-3 rounded-full mt-3" />
          <div className="bg-rp-light-gray-2 h-5 w-28 px-4 mr-3 rounded-full mt-3" />
          <div className="bg-rp-light-gray-2 h-5 w-28 px-4 mr-3 rounded-full mt-3" />
          <div className="bg-rp-light-gray-2 h-5 w-28 px-4 mr-3 rounded-full mt-3" />
        </div>
        <div className="absolute bottom-5">
          <div className="bg-rp-light-gray-2 h-3 rounded-md w-72 ml-3" />
        </div>
      </div>
      <div className="border border-rp-gray-divider mb-5 rounded-xl min-h-450 relative w-full d:w-90">
        <div className="bg-rp-light-gray-2 h-60 rounded-md px-3 pt-3" />
        <div className="bg-rp-light-gray-2 h-5 rounded-md mt-3 w-3/4 ml-3" />
        <div className="flex flex-wrap items-center text-sm py-2 ml-3">
          <div className="bg-rp-light-gray-2 h-5 w-28 px-4 mr-3 rounded-full mt-3" />
          <div className="bg-rp-light-gray-2 h-5 w-28 px-4 mr-3 rounded-full mt-3" />
          <div className="bg-rp-light-gray-2 h-5 w-28 px-4 mr-3 rounded-full mt-3" />
          <div className="bg-rp-light-gray-2 h-5 w-28 px-4 mr-3 rounded-full mt-3" />
        </div>
        <div className="absolute bottom-5">
          <div className="bg-rp-light-gray-2 h-3 rounded-md w-72 ml-3" />
        </div>
      </div>
      <div className="border border-rp-gray-divider mb-5 rounded-xl min-h-450 relative w-full d:w-90">
        <div className="bg-rp-light-gray-2 h-60 rounded-md px-3 pt-3" />
        <div className="bg-rp-light-gray-2 h-5 rounded-md mt-3 w-3/4 ml-3" />
        <div className="flex flex-wrap items-center text-sm py-2 ml-3">
          <div className="bg-rp-light-gray-2 h-5 w-28 px-4 mr-3 rounded-full mt-3" />
          <div className="bg-rp-light-gray-2 h-5 w-28 px-4 mr-3 rounded-full mt-3" />
          <div className="bg-rp-light-gray-2 h-5 w-28 px-4 mr-3 rounded-full mt-3" />
          <div className="bg-rp-light-gray-2 h-5 w-28 px-4 mr-3 rounded-full mt-3" />
        </div>
        <div className="absolute bottom-5">
          <div className="bg-rp-light-gray-2 h-3 rounded-md w-72 ml-3" />
        </div>
      </div>
    </div>
  );
}
