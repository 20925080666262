/**
 * Calculates a digest, then converts the ArrayBuffer to a hex string
 * @param message
 * @returns ArrayBuffer
 */
async function SHA256(message: string) {
  const msgUint8 = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');

  return hashHex;
}

export default SHA256;
