import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { androidAppDownloadClick, iOSAppDownloadClick } from '@events/globals';
// @ts-ignore
import AppleAppStoreRating from '@components/common/AppleAppStoreRating/AppleAppStoreRating';
import { getStaticImageUrl } from '../../utils/cloudflare';
import instagramIcon from '../../assets/images/instagram-icon.svg';
import twitterIcon from '../../assets/images/twitter-icon.svg';
import facebookIcon from '../../assets/images/facebook-icon-black.svg';
import pinterestIcon from '../../assets/images/pinterest-icon.svg';
import appleStoreLogo from '../../assets/images/apple-store-logo.svg';
import googleStoreLogo from '../../assets/images/google-store-logo.svg';
import { aboutLinks, exploreLinks } from './FooterLinks';

const socialIconStyle = 'w-5 h-5 relative mr-6 cursor-pointer';
const linkStyle = 'mb-1.5 font-rp-pn-regular tracking-tight hover:underline cursor-pointer';

const iOSAppDownloadLink = 'https://itunes.apple.com/app/id1557539492';
const androidAppDownloadLink = 'https://play.google.com/store/apps/details?id=com.resortpass.app';

export default function Footer() {
  return (
    <footer className="bg-white bottom-0 flex flex-col-reverse justify-between mb-24 px-4 z-10 d:mb-9 d:mt-12 d:flex-row d:px-6">
      <div className="flex flex-col w-full">
        <div className="mt-5 flex flex-col justify-between w-full max-w-4xl d:flex-row d:pb-16 d:pt-0">
          <div>
            <div className="w-16 h-16 relative d:mb-5">
              <div>
                <Image
                  alt="ResortPass logo"
                  src={getStaticImageUrl('/home-v2/resortpass-logo.png')}
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </div>
            <div className="flex my-10 d:pl-2 d:my-0">
              <div className="w-5 h-5 relative mr-6">
                <a
                  href="https://www.instagram.com/resortpass/"
                  target="_blank"
                  title="Open in a new window"
                  rel="noreferrer"
                >
                  <Image src={instagramIcon} layout="fill" alt="Instagram" />
                </a>
              </div>
              <div className={socialIconStyle}>
                <a
                  href="https://twitter.com/ResortPass?lang=en"
                  target="_blank"
                  title="Open in a new window"
                  rel="noreferrer"
                >
                  <Image src={twitterIcon} layout="fill" alt="Twitter" />
                </a>
              </div>
              <div className={socialIconStyle}>
                <a
                  href="https://www.facebook.com/resortpass"
                  target="_blank"
                  title="Open in a new window"
                  rel="noreferrer"
                >
                  <Image src={facebookIcon} layout="fill" alt="Facebook" />
                </a>
              </div>
              <div className={socialIconStyle}>
                <a
                  href="https://www.pinterest.com/resortpassco"
                  target="_blank"
                  title="Open in a new window"
                  rel="noreferrer"
                >
                  <Image src={pinterestIcon} layout="fill" alt="Pinterest" />
                </a>
              </div>
            </div>
          </div>
          <nav className="flex flex-col justify-between w-3/5 text-rp-primary-black-medium d:flex-row">
            <div className="flex flex-col d:mt-5">
              <p className="mb-1.5 list-none font-rp-cera-medium">Explore</p>
              {exploreLinks.map((link) => (
                <Link key={link.label} href={link.href} scroll={false}>
                  <a className={linkStyle} target={link.target} title={link.title}>
                    {link.label}
                  </a>
                </Link>
              ))}
            </div>
            <div className="flex flex-col mt-10 d:mt-5">
              <p className="mb-1.5 list-none font-rp-cera-medium">About</p>
              {aboutLinks.map((link) => (
                <Link key={link.label} href={link.href} scroll={false}>
                  <a className={linkStyle}>{link.label}</a>
                </Link>
              ))}
            </div>
          </nav>
        </div>

        <div className="font-rp-pn-light mt-10 d:mt-0">{`© ${new Date().getFullYear()} ResortPass, Inc.`}</div>
      </div>
      <div className="pt-8 pb-9 border-l-0 border-b border-solid border-rp-gray-divider d:border-l d:border-b-0 d:ml-16 d:pl-8 d:pb-0 d:pt-5">
        <div className="flex justify-center d:justify-between">
          <div className="flex flex-col justify-center d:justify-start">
            <div className="font-rp-pn-semi-bold text-lg tracking-tight mb-2.5">
              Download the
              <br />
              ResortPass App
            </div>
            <AppleAppStoreRating isFooter />
            <div className="w-32 h-10 relative mb-2 cursor-pointer">
              <Link href={iOSAppDownloadLink}>
                <a
                  onClick={() => {
                    iOSAppDownloadClick();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      iOSAppDownloadClick();
                    }
                  }}
                  target="_blank"
                  title="Open in a new window"
                  rel="noreferrer"
                  role="link"
                  tabIndex={0}
                >
                  <Image src={appleStoreLogo} layout="fill" alt="App Store" />
                </a>
              </Link>
            </div>
            <div className="w-32 h-10 relative cursor-pointer">
              <Link href={androidAppDownloadLink}>
                <a
                  onClick={() => {
                    androidAppDownloadClick();
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      androidAppDownloadClick();
                    }
                  }}
                  target="_blank"
                  title="Open in a new window"
                  rel="noreferrer"
                  role="link"
                  tabIndex={0}
                >
                  <Image src={googleStoreLogo} layout="fill" alt="Google Play" />
                </a>
              </Link>
            </div>
          </div>
          <div className="w-28 h-56 ml-12 relative">
            <Image src={getStaticImageUrl('/ios_app_image@2x.png')} layout="fill" alt="iOs App" />
          </div>
        </div>
      </div>
    </footer>
  );
}
