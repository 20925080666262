import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';

const inputStylesWithOutError =
  'w-full h-full pt-6 px-5 pb-2.5 border border-rp-gray-divider rounded-lg my-1 font-rp-pn-light text-rp-primary-black leading-5 outline-none';
const inputStylesWithError =
  'w-full h-full pt-6 px-5 pb-2.5 border border-rp-red rounded-lg my-1 font-rp-pn-light text-rp-red leading-5 outline-none';

const baseLabelStylesWithOutError = 'absolute left-5 text-rp-gray font-rp-pn-light';
const baseLabelStylesWithError = 'absolute left-5 text-rp-red font-rp-pn-light';

type Props = {
  type: string;
  id: string;
  label: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  autoComplete?: string;
  placeHolder?: string;
  error: boolean;
  focused: boolean;
  setFocused: Function;
  handleSubmit: Function;
};

const defaultProps = {
  autoComplete: 'on',
  placeHolder: '',
};

export default function FormInput({
  type,
  id,
  label,
  value,
  setValue,
  autoComplete,
  placeHolder,
  error,
  focused,
  setFocused,
  handleSubmit,
}: Props) {
  const [labelStyles, setLabelStyles] = useState(inputStylesWithOutError);
  useEffect(() => {
    const addLabelStyles =
      focused || value ? 'top-1 text-xs pt-1.5' : 'top-1/2 transform -translate-y-1/2 ';
    const baseLabels = error ? baseLabelStylesWithError : baseLabelStylesWithOutError;
    setLabelStyles(`${baseLabels} ${addLabelStyles}`);
  }, [focused, value, error]);

  return (
    <div className="w-full flex items-center justify-center relative">
      <input
        placeholder={placeHolder}
        type={type}
        id={id}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className={error ? inputStylesWithError : inputStylesWithOutError}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        autoComplete={autoComplete}
        onKeyPress={(ev: any) => (ev.key === 'Enter' ? handleSubmit(ev) : null)}
      />
      <label htmlFor={id} className={labelStyles}>
        {label}
      </label>
    </div>
  );
}

FormInput.defaultProps = defaultProps;
