import { isArray } from 'lodash';

/**
 * Find a param in the utmParam object if the conditions are met.
 * If params are arrays it will take the first element.
 *
 * Conditions:
 * utmParam.utm_source exists and matches the lower case version of source
 * if the param you're looking for exists in utmParams.
 * @param paramToLookFor string, ex: 'utm_campaign'
 * @param source string, ex: 'iterable'
 * @param utmParams object
 * @returns string | null
 */
function getParamIfSource(paramToLookFor: string, source: string, utmParams: any) {
  if (utmParams && 'utm_source' in utmParams && paramToLookFor in utmParams) {
    const sourceParam = isArray(utmParams.utm_source)
      ? utmParams.utm_source[0]
      : utmParams.utm_source;

    // check if the utm_source matches the source your looking for.
    if (sourceParam.trim().toLowerCase() === source.toLowerCase()) {
      // if is an array return the first element.
      return isArray(utmParams[paramToLookFor])
        ? utmParams[paramToLookFor][0]
        : utmParams[paramToLookFor];
    }
  }

  return null;
}

/**
 * Utility function that decodes a given URL-encoded string using the decodeURIComponent function.
 * @param string
 * @returns decoded string | string
 */
function decodeString(string: string) {
  try {
    const decodedString = decodeURIComponent(string);
    return decodedString;
  } catch (error) {
    //
  }

  return string;
}

export { getParamIfSource, decodeString };
