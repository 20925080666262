import React, { useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import { SwiperSlide } from 'swiper/react';
import PopularHotelItemDetailed from '@components/PopularHotels/PopularHotelItemDetailed';
import ButtonPrimary from '@components/common/ButtonPrimary';
import { store } from '@context/store';
import HotelApi from '@customTypes/hotel-api';
import {
  AMPLITUDE_EXPERIMENT_CONTROL_NAME,
  AMPLITUDE_EXPERIMENT_VARIANT_NAME,
} from '@constants/amplitudeExperiments';
import SwiperCarousel from '@components/common/SwiperCarousel/SwiperCarousel';
import useIsMobile from '@hooks/useIsMobile';
import styles from './PopularHotels.module.css';

type Props = {
  hotels: HotelApi[];
  selectedCityShort: string | undefined;
  selectedCityId: number | undefined;
  userCityAndState: string;
};

function HomePageNearHotels({
  hotels,
  selectedCityShort,
  selectedCityId,
  userCityAndState,
}: Props) {
  const router = useRouter();
  const {
    state: { productTilesAbTest },
  } = useContext(store);
  const isMobile = useIsMobile();
  const title = useMemo(
    () =>
      (productTilesAbTest === AMPLITUDE_EXPERIMENT_CONTROL_NAME
        ? 'Get away for the day near '
        : 'Take a daycation near '),
    [productTilesAbTest],
  );

  return (
    <>
      <div className="mx-auto text-3xl text-rp-primary-new pb-10 pt-10 tracking-normal px-5 text-center font-rp-pn-regular d:text-center d:px-0 d:font-medium d:text-4xl">
        {title}
        <span className="text-rp-primary font-rp-gelica-light-italic">
          {userCityAndState || selectedCityShort || ''}
        </span>
        {productTilesAbTest === AMPLITUDE_EXPERIMENT_CONTROL_NAME && isMobile && (
          <p className="text-sm text-center font-rp-pn-regular tracking-normal py-5 d:text-base">
            Select a date to see availability and pricing
          </p>
        )}
      </div>

      {productTilesAbTest === AMPLITUDE_EXPERIMENT_VARIANT_NAME && isMobile ? (
        <>
          <SwiperCarousel
            slidesPerView="auto"
            carouselClass="dlp-carousel product-tiles-carousel"
            loop={false}
            isNavigationEnabled={false}
            overideSlidesPerView
          >
            {hotels.map((hotel) => (
              <SwiperSlide key={hotel.id} className={styles.fixedWidth}>
                <PopularHotelItemDetailed hotel={hotel} />
                <div className="mb-8" />
              </SwiperSlide>
            ))}
          </SwiperCarousel>
          <span className="text-center block font-rp-pn-light text-base w-full">
            Select a date to see availability and pricing
          </span>
        </>
      ) : (
        <div className="flex flex-wrap flex-row items-start w-full mx-auto justify-center gap-6 gap-y-12 px-4 d:px-0 d:max-w-8xl">
          {hotels.map((hotel) => (
            <PopularHotelItemDetailed key={hotel.id} hotel={hotel} />
          ))}
        </div>
      )}

      {productTilesAbTest === AMPLITUDE_EXPERIMENT_CONTROL_NAME && selectedCityId ? (
        <div className="flex flex-col items-center justify-center px-4 mt-8 d:px-0 w-full d:w-auto d:mx-auto d:mt-12">
          <ButtonPrimary
            onClick={() => router.push(`/hotel-day-passes?city_id=${selectedCityId}`)}
            classes="d:w-auto d:px-24"
          >
            See All {selectedCityShort}
          </ButtonPrimary>
        </div>
      ) : null}

      {productTilesAbTest === AMPLITUDE_EXPERIMENT_VARIANT_NAME && !isMobile && selectedCityId ? (
        <div className="flex flex-col items-center justify-center px-4 mt-8 d:px-0 w-full d:w-auto d:mx-auto d:mt-12">
          <ButtonPrimary
            onClick={() => router.push(`/hotel-day-passes?city_id=${selectedCityId}`)}
            classes="d:w-auto d:px-24"
          >
            See All {selectedCityShort}
          </ButtonPrimary>

          <span className="block text-center text-base font-rp-pn-regular text-rp-primary-black mt-6">
            Select a date to see availability and pricing
          </span>
        </div>
      ) : null}
    </>
  );
}

export default HomePageNearHotels;
