import User from '@customTypes/user';

import { sendToGTM } from './GTM';

const createAccountClick = () => {
  sendToGTM({
    event: 'create_your_account_clicked',
  });
};

const forgotPassword = () => {
  sendToGTM({
    event: 'forgot_password_clicked',
  });
};

const login = async (user: User) => {
  sendToGTM({
    user_id: user.id,
  });

  sendToGTM({
    event: 'login',
    method: user.provider || 'email',
    klaviyo_login: {
      $email: user.email,
      $first_name: user.first_name,
      $last_name: user.last_name,
      Avatar: user.avatar,
      BirthDay: user.birth_day,
      City: user.city,
      Id: user.id,
    },
  });

  // Legacy, remove once fully migrated to GA4:
  sendToGTM({
    event: 'user_identified',
  });
};

const passwordInvalid = () => {
  sendToGTM({
    event: 'password_invalid',
  });
};

const signInScreenPageView = () => {
  sendToGTM({
    event: 'sign_in_screen_viewed',
  });
};

const signInWithFacebook = () => {
  sendToGTM({
    event: 'sign_in_with_facebook_clicked',
  });
};

const signInWithGoogle = () => {
  sendToGTM({
    event: 'sign_in_with_google_clicked',
  });
};

export {
  createAccountClick,
  forgotPassword,
  login,
  passwordInvalid,
  signInScreenPageView,
  signInWithFacebook,
  signInWithGoogle,
};
