/* eslint-disable no-nested-ternary */
import React, { ReactNode, useEffect, useMemo } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Header from '@components/Header/Header';
import useCookieConsent from '@hooks/useCookieConsent';
import { getCurrencyData } from '@utils/services';
import useSessionStorage from '@hooks/useSessionStorage';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import TagManager from '@utils/tags';
import useIsMobile from '@hooks/useIsMobile';
import { PopularHotel } from '@customTypes/popular-hotel-data';
import Footer from '../Footer/Footer';
import HeaderBanner from '../HeaderBanner/HeaderBanner';

type Props = {
  children: ReactNode;
  searchBarEnabled?: boolean;
  searchProps?: any;
  fullHeight?: boolean;
  noHeader?: boolean;
  noFooter?: boolean;
  popularHotels?: PopularHotel[];
};

const defaultProps = {
  searchBarEnabled: false,
  searchProps: {},
  fullHeight: false,
  noHeader: false,
  noFooter: false,
  popularHotels: [],
};

const pathsToHideFooter = ['guest-billing-detail', '/checkout/surveys'];
const pathsToHideFooterMobile = ['/products/checkout-confirmation'];
const pathsToHideHeader = ['/checkout/surveys'];

export default function Layout({
  children,
  searchBarEnabled,
  searchProps,
  fullHeight,
  noHeader,
  noFooter,
  popularHotels,
}: Props) {
  const router = useRouter();
  const { handleCookieConsentUpdate, allowCookies } = useCookieConsent();
  const { setItem } = useSessionStorage();

  const path = router.pathname;

  const shouldHideElement = (pathName: string, pathsToHide: string[]) =>
    pathsToHide.some((p) => pathName.includes(p));

  const isMobile = useIsMobile();

  const showFooter = useMemo(
    () =>
      !noFooter &&
      !shouldHideElement(path, [
        ...pathsToHideFooter,
        ...(isMobile ? pathsToHideFooterMobile : []),
      ]),
    [noFooter, path, isMobile],
  );

  const showHeader = useMemo(
    () => !noHeader && !shouldHideElement(path, pathsToHideHeader),
    [path, noHeader],
  );

  useEffect(() => {
    (async () => {
      try {
        // TODO: Dispatch a state change to set currencyData globally.
        // This will avoid multiple api calls like on Header.tsx
        const currencyList = await getCurrencyData();
        handleCookieConsentUpdate(currencyList.user_from_usa);
        setItem(sessionStorageKeys.USER_FROM_USA, currencyList.user_from_usa.toString());
      } catch (error) {
        // No error popup. Fail silently.
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Initialize 3rd party scripts if cookies allowed
  useEffect(() => {
    if (allowCookies) {
      TagManager.initialize();
    }
  }, [allowCookies]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5, viewport-fit=cover"
        />
      </Head>
      <HeaderBanner />

      <div className={`${fullHeight ? 'min-h-100vh flex flex-col ' : ''}`}>
        {showHeader ? (
          path === '/' ? (
            <Header searchBarEnabled={searchBarEnabled} popularHotels={popularHotels} />
          ) : (
            <Header searchBarEnabled={searchBarEnabled} searchProps={searchProps} hideVideo />
          )
        ) : null}

        {children}

        {showFooter ? <Footer /> : null}
      </div>
    </>
  );
}

Layout.defaultProps = defaultProps;
