import actionTypes from '@context/actionTypes';
import { store } from '@context/store';
import { useContext } from 'react';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import { getDateWithSlashes } from '@helpers/dateFormatter';
import useSessionStorage from './useSessionStorage';

export default function useSmartCalendar() {
  const { dispatch } = useContext(store);
  const { setItem, removeItem } = useSessionStorage();

  const removeDate = () => {
    dispatch({
      type: actionTypes.SET_SEARCH_DATE,
      payload: undefined,
    });

    removeItem(sessionStorageKeys.SMART_CALENDAR_DATE);
  };

  const setDate = (date: Date) => {
    dispatch({
      type: actionTypes.SET_SEARCH_DATE,
      payload: date,
    });

    setItem(sessionStorageKeys.SMART_CALENDAR_DATE, getDateWithSlashes(date));
  };

  return { removeDate, setDate };
}
