import React, { MouseEventHandler, ReactNode } from 'react';

type Props = {
  children: string | ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
  classes?: string;
};

const defaultProps = {
  classes: '',
};

export default function ButtonPrimaryTransparent({ children, onClick, classes }: Props) {
  const buttonStyle = `${classes} text-rp-primary-dark bg-transparent border-rp-primary-dark text-sm border-solid border rounded-xl py-3 px-7 font-rp-pn-semi-bold leading-4 cursor-pointer tracking-tight`;

  return (
    <button type="button" onClick={onClick} className={buttonStyle}>
      {children}
    </button>
  );
}

ButtonPrimaryTransparent.defaultProps = defaultProps;
