import React from 'react';
import Image from 'next/image';

type ButtonGrayProps = {
  children: React.ReactNode;
  onClick: () => void;
  text: string;
  textClasses?: string;
};

const defaultProps = {
  textClasses: '',
};

function ButtonGray({ children, onClick, text, textClasses }: ButtonGrayProps) {
  const [ButtonImage] = React.Children.toArray(children) as [React.ReactElement];

  return (
    <button
      type="button"
      onClick={onClick}
      className="flex items-center bg-rp-light-background h-52px border border-rp-gray-divider rounded-xl px-6 space-x-2 text-custom-black w-full"
    >
      {ButtonImage || null}

      <span className={`leading-18px text-sm font-medium text-left truncate ${textClasses}`}>
        {text}
      </span>
    </button>
  );
}

type ButtonGrayImageProps = {
  alt: string;
  imageSrc: string;
};

function ButtonGrayImage({ alt, imageSrc }: ButtonGrayImageProps) {
  return (
    <div className="h-4 flex-shrink-0 relative w-4">
      <Image src={imageSrc} alt={alt} layout="fill" />
    </div>
  );
}

ButtonGray.Image = ButtonGrayImage;

ButtonGray.defaultProps = defaultProps;

export default ButtonGray;
