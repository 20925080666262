import React, { Dispatch, SetStateAction, forwardRef } from 'react';
import { VisibleSelector } from '@customTypes/search';
import { useSearchContext } from '@context/SearchContext';
import ReactDatePicker from '@components/common/ReactDatePicker/ReactDatePicker';
import useIsMobile from '@hooks/useIsMobile';

type Props = {
  setShowDateSelector?: Dispatch<SetStateAction<boolean>>;
  setVisibleSelector?: Dispatch<SetStateAction<VisibleSelector>>;
};

const DateSelector = forwardRef((props: Props, dateSelectionRef: any) => {
  const { setShowDateSelector, setVisibleSelector } = props;

  const isMobile = useIsMobile();

  const {
    handleImFlexibleClick,
    isFlexibleDateSelected,
    searchDate,
    setIsFlexibleDateSelected,
    updateStartDate,
  } = useSearchContext();

  const onImFlexibleClick = () => {
    handleImFlexibleClick();

    if (isMobile && setVisibleSelector) {
      setVisibleSelector(null);
    }
  };

  const handleDateChange = (date: Date | undefined) => {
    updateStartDate(date);

    if (isMobile && setVisibleSelector) {
      setVisibleSelector(null);
    }

    if (!isMobile && setShowDateSelector) {
      setShowDateSelector(false);
    }
  };

  return (
    <div ref={dateSelectionRef} className="flex flex-col">
      <div className={`${isMobile ? 'mb-6' : ''} flex w-full`}>
        <button
          type="button"
          className={`${
            isFlexibleDateSelected ? 'bg-white text-black' : 'bg-rp-primary-black text-white'
          } w-full flex justify-center items-center rounded-tl-lg rounded-bl-lg py-2 cursor-pointer border font-rp-pn-regular text-sm d:text-base`}
          onClick={() => setIsFlexibleDateSelected(false)}
        >
          Date
        </button>

        <button
          type="button"
          className={`${
            isFlexibleDateSelected ? 'bg-rp-primary-black text-white' : 'bg-white text-black'
          } w-full flex justify-center items-center rounded-tr-lg rounded-br-lg border py-2 cursor-pointer font-rp-pn-regular text-sm d:text-base`}
          onClick={onImFlexibleClick}
        >
          I&apos;m flexible
        </button>
      </div>

      <div
        className={`${
          isFlexibleDateSelected ? 'd:opacity-10' : ''
        }  overflow-y-auto h-45svh pb-8 d:pb-0 d:overflow-auto d:h-auto`}
      >
        <ReactDatePicker
          startDate={searchDate}
          classes="font-rp-pn-regular text-rp-primary-black no-shadow w-full"
          setStartDate={handleDateChange}
          inline
          variantId={
            isMobile ? 'home-page-mobile-date-picker-variant' : 'home-page-date-picker-variant'
          }
          showClearDate={false}
        />
      </div>
    </div>
  );
});

DateSelector.defaultProps = {
  setShowDateSelector: undefined,
  setVisibleSelector: undefined,
};

export default DateSelector;
