export default function useUnbounce() {
  const hideUnbounceBanner = () => {
    const button = document.querySelector('.ub-emb-close') as HTMLButtonElement | null;

    if (button) {
      button.click();
    }
  };

  return { hideUnbounceBanner };
}
