import sessionStorageKeys from '@constants/sessionStorageKeys';
import { addFavoritesInBatch } from '@utils/services';

const unionizeFavorites = (localStorageFavorites: any, userFavorites: any) => {
  let unionizedFavorites: any = [];

  if (localStorageFavorites && localStorageFavorites !== 'null') {
    const { favorites } = JSON.parse(localStorageFavorites);
    if (favorites.length > 0) {
      unionizedFavorites = favorites;
    }
  }

  if (userFavorites && userFavorites.length > 0) {
    if (unionizedFavorites.length > 0) {
      unionizedFavorites = unionizedFavorites.concat(userFavorites);
    } else {
      unionizedFavorites = userFavorites;
    }
  }
  return unionizedFavorites;
};

const addLocalStorageHotelsToFavoritesUponLogin = (userData: any, getItem: any) => {
  const userLocalStorageFavorites = getItem(sessionStorageKeys.USER_SESSION_FAVORITES);
  if (userLocalStorageFavorites && userLocalStorageFavorites !== 'null') {
    const { favorites } = JSON.parse(userLocalStorageFavorites);
    if (favorites && favorites.length > 0) {
      addFavoritesInBatch(userData, favorites);
    }
  }
};

export { unionizeFavorites, addLocalStorageHotelsToFavoritesUponLogin };
