/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Image from 'next/image';
import { createAccountClick, forgotPassword, signInScreenPageView } from '@events/Auth';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import { useEvents } from '@events/EventsProvider';
import { SIGN_UP_INITIALIZED } from '@constants/amplitudeEvents';
import closeArrowsIcon from '../../assets/images/close-arrows.svg';
import SignUp from './SignUp';
import Login from './Login';
import ForgotPassword from './ForgotPassword';

type Props = {
  isLoginButtonClicked?: boolean | any;
  myFavoritesUserSignUp?: boolean;
  setIsLoginButtonClicked?: Dispatch<SetStateAction<boolean>> | any;
  isCheckout?: boolean;
  showSignIn?: boolean;
  hideHeader?: boolean;
  setHideHeader?: Dispatch<SetStateAction<boolean>>;
  onCloseClick?: () => void;
  isSignUpButtonClicked?: boolean;
};

const defaultProps = {
  isLoginButtonClicked: false,
  myFavoritesUserSignUp: false,
  setIsLoginButtonClicked: () => {},
  isCheckout: false,
  showSignIn: true,
  hideHeader: false,
  setHideHeader: () => null,
  onCloseClick: () => null,
  isSignUpButtonClicked: false,
};

export default function ModalContainer({
  isLoginButtonClicked,
  myFavoritesUserSignUp,
  setIsLoginButtonClicked,
  isCheckout,
  showSignIn,
  hideHeader,
  setHideHeader,
  onCloseClick,
  isSignUpButtonClicked,
}: Props) {
  const router = useRouter();
  const [cookies] = useCookies(['userInformation']);
  const { track } = useEvents();

  const [isSignUpClicked, setIsSignUpClicked] = useState<boolean>(false);
  const [isForgotPasswordClicked, setIsForgotPasswordClicked] = useState(false);

  useEffect(() => {
    if (window.location.href.includes('sign-in') && cookies.userInformation?.email) {
      router.push('/users/bookings');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  useEffect(() => {
    if (window && window.location) {
      if (window.location.href.includes('sign-up') || myFavoritesUserSignUp) {
        setIsSignUpClicked(true);
      } else {
        setIsSignUpClicked(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSignUpClicked) {
      track(SIGN_UP_INITIALIZED, {});
    }
  }, [isSignUpClicked, track]);

  useEffect(() => {
    if (isSignUpClicked) {
      // Event
      createAccountClick();
    }
  }, [isSignUpClicked]);

  useEffect(() => {
    if (isForgotPasswordClicked) {
      // Event
      forgotPassword();
    }
  }, [isForgotPasswordClicked]);

  useEffect(() => {
    // Event
    signInScreenPageView();
  }, []);

  useEffect(() => {
    if (!hideHeader) {
      setIsForgotPasswordClicked(false);
    }
  }, [hideHeader]);

  useEffect(() => {
    if (isCheckout) {
      setIsSignUpClicked(!showSignIn);
    }
  }, [isCheckout, showSignIn]);

  useEffect(() => {
    if (isSignUpButtonClicked) {
      setIsSignUpClicked(isSignUpButtonClicked);
    }
  }, [isSignUpButtonClicked]);

  const primaryUnderlineLinkClasses = 'font-rp-pn-semi-bold text-rp-primary-light underline ml-2';
  const showCloseButton =
    typeof window !== 'undefined' && !window.location.href.includes('sign-in');
  return isForgotPasswordClicked ? (
    <ForgotPassword
      setIsLoginButtonClicked={setIsLoginButtonClicked}
      setIsForgotPasswordClicked={setIsForgotPasswordClicked}
      isCheckout={isCheckout}
      setHideHeader={setHideHeader}
      onCloseClick={onCloseClick}
    />
  ) : (
    <div
      className={`relative bg-white flex items-center pb-9 mx-3 my-10 d:max-w-lg d:mx-auto ${
        isCheckout
          ? 'mr-3 d:bg-rp-primary-new d:mt-2'
          : 'border rounded-xl shadow-lg w-11/12 px-5 pt-7 d:my-8'
      }`}
    >
      {showCloseButton && !isCheckout && (
        <button
          type="button"
          onClick={() => setIsLoginButtonClicked(false)}
          className="absolute top-5 right-5"
        >
          <Image src={closeArrowsIcon} alt="close-icon" />
        </button>
      )}
      <div className="w-full font-rp-pn-regular text-rp-primary-black">
        {!isCheckout && (
          <div>
            <div className="text-2xl text-center font-rp-pn-semi-bold mb-5 d:text-3xl d:mb-4">
              {`${isSignUpClicked ? 'Create ResortPass Account' : 'Log in to ResortPass'}`}
            </div>
            <div className="mb-8 text-center">
              <p className="d:inline-block">
                {`${isSignUpClicked ? 'Already have an account?' : "Don't have an account yet?"}`}
              </p>
              <button
                type="button"
                className={primaryUnderlineLinkClasses}
                onClick={() => setIsSignUpClicked(!isSignUpClicked)}
              >
                {`${isSignUpClicked ? 'Log in' : 'Sign Up'}`}
              </button>
            </div>
          </div>
        )}

        {isSignUpClicked ? (
          <SignUp
            setIsLoginButtonClicked={setIsLoginButtonClicked}
            isLoginButtonClicked={myFavoritesUserSignUp ? false : isLoginButtonClicked}
            isCheckout={isCheckout}
          />
        ) : (
          <Login
            setIsLoginButtonClicked={setIsLoginButtonClicked}
            isLoginButtonClicked={myFavoritesUserSignUp ? false : isLoginButtonClicked}
            setIsForgotPasswordClicked={setIsForgotPasswordClicked}
            isCheckout={isCheckout}
            setHideHeader={setHideHeader}
          />
        )}
      </div>
    </div>
  );
}

ModalContainer.defaultProps = defaultProps;
