import React, { useState, useRef, useContext } from 'react';
import Image from 'next/image';
import useOutsideClick from '@hooks/useOutsideClick';
import ButtonPrimary from '@components/common/ButtonPrimary';
import searchIcon from '@assets/images/search-icon-white.svg';
import LocationResultsList from '@components/SearchBar/LocationResultsList';
import { useSearchContext } from '@context/SearchContext';
import { SEARCH_DEFAULT_LOCATIONS } from '@constants/SearchDefaultOptions';
import { store } from '@context/store';
import SelectSearchInput from './SelectSearchInput';
import DateSelector from './DateSelector';
import ClearButton from './ClearButton';

export default function HeaderSearchBar() {
  const [showDateSelector, setShowDateSelector] = useState<boolean>(false);
  const [showLocationResults, setShowLocationResults] = useState<boolean>(false);

  const {
    state: { userLocation },
  } = useContext(store);

  const { formattedDate, handleSearchClick, searchResults, setSearchResults } = useSearchContext();

  const locationRemovalRef = useRef<HTMLDivElement>(null);

  const [locationSelectionRef] = useOutsideClick(
    () => setShowLocationResults(false),
    'react-select-options-home-new__option',
    locationRemovalRef,
  );

  const [dateSelectionRef] = useOutsideClick(() => setShowDateSelector(false));

  // On click of the input, open the location search & show default locations
  const handleOnInputClick = () => {
    setShowLocationResults(true);
    setSearchResults(
      userLocation ? [userLocation, ...SEARCH_DEFAULT_LOCATIONS] : SEARCH_DEFAULT_LOCATIONS,
    );
  };

  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-w-380 max-w-552 w-full">
      <div className="h-50 border bg-white rounded-lg z-20 flex justify-between w-full">
        <div className="flex w-full py-2">
          {/* Location Search */}
          <div className="border-r flex flex-col w-full cursor-pointer justify-center px-6 font-rp-pn-regular">
            <div className="flex items-start relative rounded-t-md d:rounded-lg d:rounded-tr-none justify-between w-full">
              <SelectSearchInput onClick={handleOnInputClick} />

              <ClearButton ref={locationRemovalRef} showLocationResults={showLocationResults} />
            </div>
          </div>

          {/* Date Picker */}
          <div className="flex flex-col flex-shrink-0 justify-center cursor-pointer min-w-124 pl-6">
            <div
              onClick={() => setShowDateSelector(true)}
              onKeyDown={() => setShowDateSelector(true)}
              role="button"
              tabIndex={-1}
              className="font-rp-pn-regular flex flex-col font-medium justify-center text-18 leading-18px"
            >
              {formattedDate}
            </div>
          </div>
        </div>

        <div className="flex h-10 items-center m-1 w-52px">
          <ButtonPrimary onClick={handleSearchClick} classes="h-full">
            <Image src={searchIcon} alt="search-icon" width={18} height={18} />
          </ButtonPrimary>
        </div>
      </div>

      {(showLocationResults && searchResults.length) || showDateSelector ? (
        <div
          className={`bg-white fixed mt-2 max-h-470px rounded-lg shadow-search-results w-full z-20 ${
            showDateSelector ? 'overflow-hidden px-72px py-5' : 'overflow-auto pt-6 pb-8'
          }`}
        >
          {showDateSelector ? (
            <DateSelector setShowDateSelector={setShowDateSelector} ref={dateSelectionRef} />
          ) : null}

          {/* Results */}
          {showLocationResults && searchResults ? (
            <LocationResultsList
              onResultClick={() => {
                setShowLocationResults(false);
              }}
              ref={locationSelectionRef}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}
