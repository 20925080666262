import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { SEARCH_DEFAULT_LOCATIONS } from '@constants/SearchDefaultOptions';
import { useSearchContext } from '@context/SearchContext';
import { store } from '@context/store';
import locationPin from '@assets/images/location-icon.svg';
import Image from 'next/image';
import { VisibleSelector } from '@customTypes/search';
import LocationResultsList from '../LocationResultsList';
import ClearButton from '../ClearButton';
import SelectSearchInput from '../SelectSearchInput';

type Props = {
  setVisibleSelector: Dispatch<SetStateAction<VisibleSelector>>;
};

export default function LocationSelector({ setVisibleSelector }: Props) {
  const [showLocationResults, setShowLocationResults] = useState(false);

  const { searchResults, setSearchResults } = useSearchContext();

  const {
    state: { userLocation },
  } = useContext(store);

  // Set the popular search results the first time the component loads.
  useEffect(() => {
    setShowLocationResults(true);
    setSearchResults(
      userLocation ? [userLocation, ...SEARCH_DEFAULT_LOCATIONS] : SEARCH_DEFAULT_LOCATIONS,
    );
  }, [setSearchResults, userLocation]);

  return (
    <div className="flex flex-col text-rp-custom-black w-full">
      <div className="flex flex-col mt-8 px-4 w-full">
        <h4 className="text-18 leading-18px font-rp-pn-semi-bold">Location</h4>

        <div className="flex items-center bg-rp-light-background h-52px mt-4 border border-rp-gray-divider rounded-xl pr-12 px-6 relative text-custom-black w-full">
          <div className="h-4 flex-shrink-0 mr-2 relative w-4">
            <Image src={locationPin} alt="location-icon" layout="fill" />
          </div>

          <SelectSearchInput
            onClick={() => {
              setShowLocationResults(true);
            }}
          />

          <div className="absolute mr-6 right-0 top-4">
            <ClearButton showLocationResults={showLocationResults} />
          </div>
        </div>
      </div>

      <div className="mt-2">
        {/* Results */}
        {showLocationResults && searchResults ? (
          <div id="search-results" className="h-60svh overflow-auto px-10 pb-20 w-full">
            <LocationResultsList
              onResultClick={() => {
                setShowLocationResults(false);
                setVisibleSelector(null);
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
