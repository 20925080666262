/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, Dispatch, SetStateAction, useCallback } from 'react';
import Image from 'next/image';
import eyeOpen from '@assets/images/password-eye-open.svg';
import eyeClosed from '@assets/images/password-eye-closed.svg';

const baseLabelStylesWithOutError = 'absolute left-5 text-rp-gray font-rp-pn-light';
const baseLabelStylesWithError = 'absolute left-5 font-rp-pn-light';

type Props = {
  type: string;
  id: string;
  label: string;
  value: string;
  setValue: Dispatch<SetStateAction<any>>;
  error: boolean;
  autoComplete?: string;
  placeHolder?: string;
  noBorderRightRadius?: boolean;
  noBorderLeftRadius?: boolean;
  noBorderRight?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
};

const defaultProps = {
  autoComplete: 'on',
  placeHolder: '',
  noBorderRightRadius: false,
  noBorderLeftRadius: false,
  noBorderRight: false,
  onBlur: () => null,
  onFocus: () => null,
};

export default function CheckoutFormInput({
  type,
  id,
  label,
  value,
  setValue,
  autoComplete,
  placeHolder,
  error,
  noBorderRightRadius = false,
  noBorderLeftRadius = false,
  noBorderRight = false,
  onBlur = () => null,
  onFocus = () => null,
}: Props) {
  const border = noBorderRight ? 'border-l border-t border-b' : 'border';
  const borderRadius = noBorderRightRadius
    ? 'rounded-l-lg rounded-r-none'
    : noBorderLeftRadius
    ? 'rounded-r-lg rounded-l-none'
    : 'rounded-lg';
  const inputStylesWithOutError = `w-full h-full pt-6 px-5 pb-2.5 border-rp-dark-border my-1 font-rp-pn-light text-rp-primary-black leading-5 outline-none ${borderRadius} ${border}`;
  const inputStylesWithError = `w-full h-full pt-6 px-5 pb-2.5 border-rp-danger-2 bg-rp-danger-3 my-1 font-rp-pn-light leading-5 outline-none ${borderRadius} ${border}`;

  const [labelStyles, setLabelStyles] = useState(inputStylesWithOutError);
  const [focused, setFocused] = useState(false);
  const [passwordFieldType, setPasswordFieldType] = useState('password');

  useEffect(() => {
    const addLabelStyles =
      focused || value ? 'top-1 text-xs pt-1.5' : 'top-1/2 transform -translate-y-1/2 ';
    const baseLabels = error ? baseLabelStylesWithError : baseLabelStylesWithOutError;
    setLabelStyles(`${baseLabels} ${addLabelStyles}`);
  }, [focused, value, error]);

  const onEyeClick = useCallback(() => {
    if (passwordFieldType === 'password') {
      setPasswordFieldType('text');
      return;
    }
    setPasswordFieldType('password');
  }, [passwordFieldType]);

  return (
    <div className="w-full flex items-center justify-center relative">
      {type === 'password' && (
        <div className="absolute h-5 w-6 right-3 cursor-pointer">
          <Image
            src={passwordFieldType === 'password' ? eyeOpen : eyeClosed}
            layout="fill"
            alt="eye"
            onClick={() => onEyeClick()}
          />
        </div>
      )}
      <input
        placeholder={placeHolder}
        type={type === 'password' ? passwordFieldType : type}
        id={id}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className={error ? inputStylesWithError : inputStylesWithOutError}
        onFocus={() => {
          setFocused(true);
          if (onFocus) onFocus();
        }}
        onBlur={() => {
          setFocused(false);
          if (onBlur) onBlur();
        }}
        autoComplete={autoComplete}
      />

      <label htmlFor={id} className={labelStyles}>
        {label}
      </label>
    </div>
  );
}

CheckoutFormInput.defaultProps = defaultProps;
