import React, { Dispatch, SetStateAction, useState } from 'react';
import ButtonPrimary from '@components/common/ButtonPrimary';
import { useSearchContext } from '@context/SearchContext';
import BottomDrawerVariant from '@components/common/BottomDrawer/BottomDrawerVariant';
import { VisibleSelector } from '@customTypes/search';
import DefaultSelector from './DefaultSelector';
import DateSelectorWrapper from './DateSelectorWrapper';
import LocationSelector from './LocationSelector';

type Props = {
  setShowBottomDrawer: Dispatch<SetStateAction<boolean>>;
};

export default function SearchDrawer({ setShowBottomDrawer }: Props) {
  const [visibleSelector, setVisibleSelector] = useState<VisibleSelector>(null);

  const { handleSearchClick } = useSearchContext();

  return (
    <BottomDrawerVariant isOpen setIsOpen={setShowBottomDrawer} title="Search">
      {visibleSelector === 'date' ? (
        <DateSelectorWrapper setVisibleSelector={setVisibleSelector} />
      ) : null}

      {visibleSelector === 'location' ? (
        <LocationSelector setVisibleSelector={setVisibleSelector} />
      ) : null}

      {visibleSelector === null ? (
        <DefaultSelector setVisibleSelector={setVisibleSelector} />
      ) : null}

      {/* Search Button */}
      <div className="absolute bottom-0 flex items-center justify-center mt-3 bg-white border-t border-rp-gray-divider px-4 h-20 w-full">
        <ButtonPrimary classes="h-12 w-full" onClick={handleSearchClick}>
          Search
        </ButtonPrimary>
      </div>
    </BottomDrawerVariant>
  );
}
