type Evaluate = boolean | (() => boolean);

export default function ClassName(toEvaluate: { [key: string]: Evaluate }) {
  const evaluatedClasses = Object.keys(toEvaluate).reduce((arr: Array<string>, key: string) => {
    if (typeof toEvaluate[key] === 'function') {
      if ((toEvaluate[key] as () => boolean)()) arr.push(key);
    }
    if (toEvaluate[key] === true) arr.push(key);
    return arr;
  }, []);
  return evaluatedClasses.join(' ');
}
