// TODO: deprecate function call in favor of environment object.
const isDevEnvironment = (): boolean =>
  process && process.env.NEXT_PUBLIC_NEXT_ENV === 'development';
const isNotDevEnvironment = (): boolean =>
  process && process.env.NEXT_PUBLIC_NEXT_ENV !== 'development';

const environment = {
  isDevelopment: process && process.env.NEXT_PUBLIC_NEXT_ENV === 'development',
  isProduction: process && process.env.NEXT_PUBLIC_NEXT_ENV === 'production',
  isStaging: process && process.env.NEXT_PUBLIC_NEXT_ENV === 'staging',
};

export { environment, isDevEnvironment, isNotDevEnvironment };
