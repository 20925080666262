import React from 'react';
import Image from 'next/image';
import starSolid from '../../assets/images/star-solid.svg';
import starHalf from '../../assets/images/star-half.svg';
import starSolidBlue from '../../assets/images/star-solid-blue-redesign.svg';
import starHalfBlue from '../../assets/images/star-half-blue-redesign.svg';

type Props = {
  rating: string;
  size: string;
  isDesignSystemStar?: boolean;
  isRPColor?: boolean;
};

/**
 * DEPRECATED: in favor of RatingNumber
 */
export default function StarRating({ rating, size, isDesignSystemStar, isRPColor }: Props) {
  const starRatings = rating.split('.');
  const fullStars = +starRatings[0];
  const isHalfStar = !!starRatings[1] && +starRatings[1] > 0;
  const fullStarsArray = new Array(fullStars).fill(0).map((_, i) => i + 1);
  const solidStar = isDesignSystemStar || isRPColor ? starSolidBlue : starSolid;
  const halfStar = isDesignSystemStar || isRPColor ? starHalfBlue : starHalf;

  return (
    <div className="flex text-sm">
      {fullStarsArray.map((item: number) => (
        <div key={item} className={`h-${size} w-${size} ${!isDesignSystemStar && 'mr-0.5'}`}>
          <Image src={solidStar} alt="Star Rating Icon" />
        </div>
      ))}
      {isHalfStar && (
        <div className="flex justify-end relative">
          <div className={`h-${size} w-${size}`}>
            <Image src={halfStar} alt="Half Star Rating Icon" />
          </div>
        </div>
      )}
    </div>
  );
}

StarRating.defaultProps = {
  isDesignSystemStar: false,
  isRPColor: false,
};
