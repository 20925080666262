import { useCallback, useEffect, useRef } from 'react';

/**
 * Hook to listen for outside component clicks and then performs an action.
 * @param callback function to execute if the user clicks outside of the component
 * @returns ref
 */
const useOutsideClick = (
  callback: Function,
  optionalParent?: string,
  excludedElementRef?: React.RefObject<HTMLElement>,
) => {
  const ref = useRef(null);

  const getOptionalParent = useCallback((target: Node | null, className: string): boolean => {
    if (!target || !target?.parentElement) return false;
    let isChild = false;
    const classList = target?.parentElement?.classList;

    if (!classList?.contains(className)) {
      isChild = getOptionalParent(target?.parentNode as Node, className);
    } else {
      isChild = true;
    }

    return isChild;
  }, []);

  const handleClick = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (ref.current && event.target instanceof Node) {
        const current = ref.current as HTMLElement;
        const target = event.target as HTMLElement;

        if (
          !current.contains(target) &&
          (!excludedElementRef ||
            !excludedElementRef.current ||
            !excludedElementRef.current.contains(target)) &&
          (!optionalParent || !getOptionalParent(target, optionalParent))
        ) {
          callback(event);
        }
      }
    },
    [callback, getOptionalParent, excludedElementRef, optionalParent],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  }, [handleClick]);

  return [ref];
};

export default useOutsideClick;
