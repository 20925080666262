type FilterType = {
  [key: string]: string | number;
};

const FILTER_CATEGORY_STAR_CLASSIFICATION = 'star_classification';
const FILTER_CATEGORY_HOTEL_CLASS = 'hotel_class';
const FILTER_CATEGORY_AMENITIES = 'amenities';
const FILTER_CATEGORY_GUEST_RATING = 'guest_rating';
const FILTER_CATEGORY_HOTEL_VIBES = 'hotel_vibes';

const FILTER_CATEGORY_VALUES: FilterType = {
  star_classification: 'star_classification',
  amenities: 'amenities.name',
  guest_rating: 'rating',
};

const FILTER_CATEGORY_VALUES_SEARCH_V5: FilterType = {
  ...FILTER_CATEGORY_VALUES,
  star_classification: 'hotel_star',
};

const FILTER_EVENT_NAMES: any = {
  hotel_class: {
    '=5': 'filters_hotel_class_5_star_clicked',
    '>=4': 'filters_hotel_class_4+_star_clicked',
    '>=3': 'filters_hotel_class_3+_star_clicked',
  },
  guest_rating: {
    '>=3.8': 'filters_guest_rating_top_rated_clicked',
  },
  amenities: {
    beach: 'filters_amenities_beach_clicked',
    spa: 'filters_amenities_spa_clicked',
    hottub: 'filters_amenities_hot_tub_clicked',
    lazyriver: 'filters_amenities_lazy_river_clicked',
    'fitness-center': 'filters_amenities_fitness_clicked',
  },
};

export {
  FILTER_CATEGORY_VALUES,
  FILTER_CATEGORY_VALUES_SEARCH_V5,
  FILTER_CATEGORY_STAR_CLASSIFICATION,
  FILTER_CATEGORY_HOTEL_CLASS,
  FILTER_CATEGORY_AMENITIES,
  FILTER_CATEGORY_HOTEL_VIBES,
  FILTER_CATEGORY_GUEST_RATING,
  FILTER_EVENT_NAMES,
};
