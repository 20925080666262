import actionTypes from '@context/actionTypes';
import { store } from '@context/store';
import { Currency, CurrencyData } from '@customTypes/currency';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import useSessionStorage from '@hooks/useSessionStorage';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

type Props = {
  currencyData: CurrencyData | undefined;
  setIsCurrencySelectorModalOpen: Dispatch<SetStateAction<boolean>>;
};

export default function CurrencyList({ currencyData, setIsCurrencySelectorModalOpen }: Props) {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { selectedCurrency } = state;
  const { setItem } = useSessionStorage();
  const [alphabeticalCurrencies, setAlphabeticalCurrencies] = useState<CurrencyData>();

  const updateSelectedCurrency = (item: Currency) => {
    dispatch({
      type: actionTypes.UPDATE_SELECTED_CURRENCY,
      payload: item,
    });
    setIsCurrencySelectorModalOpen(false);
    setItem(sessionStorageKeys.SELECTED_CURRENCY, JSON.stringify(item));
  };

  useEffect(() => {
    if (currencyData) {
      const newList = currencyData;
      const filteredCurrencies = currencyData.currencies.filter(
        (currency) => currency.name !== selectedCurrency.name,
      );
      const sortedCurrencies = filteredCurrencies.sort((a, b) => a.name.localeCompare(b.name));
      sortedCurrencies.unshift(selectedCurrency);
      newList.currencies = sortedCurrencies;
      if (sortedCurrencies) setAlphabeticalCurrencies(newList);
    }
  }, [currencyData, selectedCurrency]);

  return (
    <div className="flex flex-col">
      <div className="px-6 border-t border-rp-gray-divider mt-4">
        <p className="font-rp-pn-semi-bold text-23 py-3">Choose a currency</p>
        <div className="flex flex-wrap text-15 items-center gap-4">
          {alphabeticalCurrencies ? (
            alphabeticalCurrencies.currencies.map((item: Currency) => {
              const isSelected = item.name === selectedCurrency.name;
              return (
                <button
                  type="button"
                  key={item.name}
                  onClick={() => updateSelectedCurrency(item)}
                  className={`flex flex-col px-4 py-2 rounded-xl min-w-190 cursor-pointer hover:bg-rp-light-gray-4 border ${
                    isSelected ? 'border-rp-primary-black' : 'border-transparent'
                  }`}
                >
                  <p>{item.name}</p>
                  <p>{`${item.iso_code} - ${item.symbol}`}</p>
                </button>
              );
            })
          ) : (
            <div className="py-4 h-544 d:h-auto d:min-w-330">No currencies available</div>
          )}
        </div>
      </div>
    </div>
  );
}
