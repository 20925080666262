import React, { Dispatch, SetStateAction } from 'react';
import ButtonGray from '@components/common/ButtonGray';
import { useSearchContext } from '@context/SearchContext';
import calendarIcon from '@assets/images/calendar-icon-fill.svg';
import { VisibleSelector } from '@customTypes/search';
import DateSelector from '../DateSelector';

type Props = {
  setVisibleSelector: Dispatch<SetStateAction<VisibleSelector>>;
};

export default function DateSelectorWrapper({ setVisibleSelector }: Props) {
  const { formattedDate } = useSearchContext();

  return (
    <div className="flex flex-col text-rp-custom-black w-full">
      <div className="flex flex-col mt-8 px-4 w-full">
        <h4 className="text-18 leading-18px font-rp-pn-semi-bold">Date</h4>

        <div className="mt-4 w-full">
          <ButtonGray text={formattedDate} onClick={() => setVisibleSelector(null)}>
            <ButtonGray.Image imageSrc={calendarIcon} alt="calendar-icon" />
          </ButtonGray>
        </div>

        <div className="mt-6">
          <DateSelector setVisibleSelector={setVisibleSelector} />
        </div>
      </div>
    </div>
  );
}
