const sessionStorageKeys = {
  ALGOLIA_TRACKED_HOTEL: 'algolia_tracked_hotel',
  ALGOLIA_TYPE_AHEAD: 'algolia_type_ahead',
  CART_ID: 'cart_id',
  CART_DATE: 'cart_date',
  CART_NUMBER_OF_ITEMS: 'cart_number_of_items',
  SMART_CALENDAR_DATE: 'smart_calendar_date',
  BOOKING_ID: 'booking_id',
  SEARCHED_LOCATION: 'searched_location',
  SELECTED_CURRENCY: 'selected_currency',
  USER_FROM_USA: 'user_from_usa',
  PRESERVE_SRP_SCROLL: 'preserve_srp_scroll',
  NO_RESULTS_SEARCH_TERM: 'no_results_search_term',
  USER_SESSION_FAVORITES: 'user_session_favorites',
  COLLAPSED_PRODUCT_CARD_DETAILS: 'collapsed_product_card_details',
  UTM_PARAMS: 'utm_params',
  SESSION_ID: 'session_id',
  SRP_FILTERS: 'srp_filters',
  SHOW_ONLY_AVAILABLE_FILTER: 'show_only_available_filter',
  USER_CITY_AND_STATE: 'user_city_and_state',
  ITERABLE_EMAIL: 'iterable_email',
  PAYMENT_METHODS: 'payment_methods',
  IS_AMPLITUDE_VARIANT: 'is_amplitude_variant',
  POST_CHECKOUT_SURVEY: 'post_checkout_survey',
  COUPON: 'coupon',
};

export default sessionStorageKeys;
