import React from 'react';

type Props = {
  onClearClick: () => void;
};

export default function ClearDateSection({ onClearClick }: Props) {
  return (
    <div className="date-footer">
      <div className="inline-block mt-2 px-5 pb-2 w-full">
        <div className="flex items-center justify-center">
          <button
            onClick={onClearClick}
            type="button"
            className="h-10 text-base rounded-xl px-5 hover:bg-rp-light-gray-4"
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
}
