import { canUseGTM } from '@events/GTM';
import doOnce from '@utils/doOnce';

import Snippets from './snippets';

/**
 * Generate a simple tag and fill it with some content.
 * @param tagType the type of the tag to create. ex: 'noscript' | 'script' | 'style'
 * @param content the content of the tag.
 * @returns a new document element
 */
const scriptGenerator = (tagType: 'noscript' | 'script' | 'style', content: any) => {
  const element = window.document.createElement(tagType);
  element.innerHTML = content;

  return element;
};

const TagManager = {
  scripts: {
    gtmMainScript: () => scriptGenerator('script', Snippets.gtmMainScript),
    gtmNoScript: () => scriptGenerator('noscript', Snippets.gtmNoScript),
  },

  /**
   * Inject script tags into code.
   */
  initialize: doOnce(() => {
    if (canUseGTM) {
      // GTM
      document.head.insertBefore(TagManager.scripts.gtmMainScript(), document.head.childNodes[0]);
      document.body.insertBefore(TagManager.scripts.gtmNoScript(), document.body.childNodes[0]);
    }
  }),
};

export default TagManager;
