import User from '@customTypes/user';

import SHA256 from '@helpers/encoders';

import { sendToGTM } from './GTM';

const androidAppDownloadClick = () => {
  sendToGTM({
    event: 'google_play_download_clicked',
  });
};

const buyGiftCardClicked = () => {
  sendToGTM({
    event: 'buy_gift_card_clicked',
  });
};

const cartIconClick = () => {
  sendToGTM({
    event: 'cart_icon_clicked',
  });
};

const contactPageView = () => {
  sendToGTM({
    event: 'contact_page_view',
  });
};

const iOSAppDownloadClick = () => {
  sendToGTM({
    event: 'app_store_download_clicked',
  });
};

const zipCodeToolTipViewed = (): void => {
  sendToGTM({
    event: 'zip_code_tool_tip_viewed',
  });
};

// Navigation sidebar Events:

const becomeHotelPartnerClick = () => {
  sendToGTM({
    event: 'become_hotel_partner_clicked',
  });
};

const browseHotelsClick = () => {
  sendToGTM({
    event: 'browse_hotels_clicked',
  });
};

const contactUsClick = () => {
  sendToGTM({
    event: 'contact_us_clicked',
  });
};

const receiveSpecialOffersOptedIn = (value: boolean, cartId: string) => {
  sendToGTM({
    event: 'receive_special_offers_opted_in',
    receive_special_offers: {
      value,
      event_id: cartId,
    },
  });
};

const giftCardClick = () => {
  sendToGTM({
    event: 'gift_cards_clicked',
  });
};

const hotelLoginClick = () => {
  sendToGTM({
    event: 'hotel_login_clicked',
  });
};

const loginInClick = () => {
  sendToGTM({
    event: 'log_in_sign_up_clicked',
  });
};

const logOutClick = () => {
  sendToGTM({
    event: 'log_out_clicked',
  });
};

const search = async (search_term: string, algolia_search_id: string, date: Date | undefined) => {
  sendToGTM({
    event: 'search',
    search_term,
    algolia_search_id,
  });

  if (window.Iterable) {
    await window.Iterable.search(search_term, date);
  }
};

const sojernPageView = async (url: string, user: User, sessionID: string) => {
  const email = user?.email ? await SHA256(user?.email.toLowerCase().trim()) : null;

  sendToGTM({
    event: 'sojern_page_view',
    sojern_page_view: {
      pc: url,
      sha256_eml: email,
      ccid: user ? user.id?.toString() : sessionID,
    },
  });
};

const userCreated = (user: User) => {
  sendToGTM({
    event: 'user_created',
    facebook_user: {
      event_id: user.id,
    },
  });
};

const preSelectedLocationClicked = (cityName: string) => {
  sendToGTM({
    event: 'preselected_location_clicked',
    home_page: { city_name: cityName },
  });
};

const popularLocationClicked = (cityName: string) => {
  sendToGTM({
    event: 'popular_location_clicked',
    home_page: { city_name: cityName },
  });
};

export {
  androidAppDownloadClick,
  buyGiftCardClicked,
  becomeHotelPartnerClick,
  browseHotelsClick,
  cartIconClick,
  contactPageView,
  contactUsClick,
  receiveSpecialOffersOptedIn,
  giftCardClick,
  hotelLoginClick,
  iOSAppDownloadClick,
  loginInClick,
  logOutClick,
  search,
  sojernPageView,
  userCreated,
  zipCodeToolTipViewed,
  preSelectedLocationClicked,
  popularLocationClicked,
};
