const ignoreErrors = [
  // Email link Microsoft Outlook crawler compatibility error
  // cf. https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
  'Non-Error exception captured',
  'Non-Error promise rejection captured',
  'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
  // Ignore error coming from Facebook, related to newsletter popup.
  'Blocked a frame with origin "https://www.resortpass.com" from accessing a cross-origin frame. Protocols, domains, and ports must match.',
  "Can't find variable: _AutofillCallbackHandler",
  "Can't find variable: _pcmBridgeCallbackHandler",
  'Failed to load Stripe.js', // ignoring this as it's not causing any user experience issues
  "Unexpected token '='",
  "Can't find variable: setIOSParameters",
  "Unexpected identifier 'Promise'",
  "Unexpected token '...'. Expected a property name.",
  'Unexpected token {',
  'validation failed',
  /ibFindAllVideos/,
  /Invariant: attempted to hard navigate to the same URL/,
  'Attempt to use history.replaceState() more than 100 times per 30 seconds', // new relic internal error
  /_error.js getInitialProps missing data at path:/,
  /SecurityError: The operation is insecure/, // Firefox privacy and security features.
  /timeout exceeded/,
  'Network Error', // Need to fix.,
  'Request failed with status code 401',
];

export default ignoreErrors;
