import months from '@constants/monthNameList';

import { format, getDate, getMonth, getYear } from 'date-fns';

/**
 * @param Date object
 * @returns month name, day name, year ex: "October 19, 1991"
 */
const getDetailedDate = (date: Date) => {
  if (!date) {
    return '';
  }
  const day = getDate(date);
  const monthNumber = getMonth(date);
  const monthName = months[monthNumber];
  const year = getYear(date);
  return `${monthName} ${day}, ${year}`;
};

/**
 * @param Date object
 * @returns year, month, day ex: "1991-10-19"
 */
const getDateWithDashes = (date: Date) => {
  if (!date) {
    return '';
  }
  const day = getDate(date);
  const dayText = day < 10 ? `0${day}` : day;
  const monthNumber = getMonth(date);
  const monthText = monthNumber < 9 ? `0${monthNumber + 1}` : monthNumber + 1;
  const year = getYear(date);
  return `${year}-${monthText}-${dayText}`;
};

const getDateWithSlashes = (date: Date, dayFirst: boolean = false) => {
  if (!date) {
    return '';
  }

  const day = getDate(date);
  const dayText = day < 10 ? `0${day}` : day;
  const monthNumber = getMonth(date);
  const monthText = monthNumber < 9 ? `0${monthNumber + 1}` : monthNumber + 1;
  const year = getYear(date);
  return dayFirst ? `${dayText}/${monthText}/${year}` : `${monthText}/${dayText}/${year}`;
};

const getDetailedDateWithDayOfWeek = (date: Date) => {
  if (!date) {
    return '';
  }
  let dayValue = '';
  const detailedDate = getDetailedDate(date);
  if (date instanceof Date) {
    dayValue = format(date, 'EEEE');
  }
  return `${dayValue}, ${detailedDate}`;
};

const getDetailedDateWithDayOfWeekShort = (date: Date, showYear?: boolean) => {
  if (!date) {
    return '';
  }
  const day = getDate(date);
  const year = getYear(date);
  const monthNumber = getMonth(date);
  const monthName = months[monthNumber];
  const dayValue = format(date, 'EEEE');
  return `${dayValue.slice(0, 3)}, ${monthName.slice(0, 3)} ${day}${showYear ? `, ${year}` : ''}`;
};

/**
 * @param str a string representation of a date ex: "2022-03-30"
 * @returns day name, month name, day date ex: "Wednesday, March 30"
 */
const getBookedDate = (str: string) => {
  const ymd = str.split('-');
  const newDate = new Date(+ymd[0], +ymd[1] - 1, +ymd[2]);
  const dayValue = format(newDate, 'EEEE');
  const day = getDate(newDate);
  const monthNumber = getMonth(newDate);
  const monthName = months[monthNumber];
  return `${dayValue}, ${monthName} ${day}`;
};

/**
 * Convert military time string to 12 hours.
 * @param time as string, ex: "10:00:00" | "14:00:00"
 * @param showMinutes boolean
 * @returns string, ex: "10:00am | 02:00pm"
 */
const parseMilitaryTime = (time: string | undefined, showMinutes = true) => {
  if (!time) return '';

  const [hours, minutes] = time.split(':');

  // eslint-disable-next-line no-nested-ternary
  const parsedTime = +hours === 0 ? 12 : +hours <= 12 ? +hours : +hours - 12;

  return `${parsedTime.toString()}${showMinutes ? `:${minutes}` : ''}${+hours < 12 ? 'am' : 'pm'}`;
};

/**
 * @param Date object
 * @returns month name, year ex: "Oct 1991"
 */
const getMonthAndYear = (date: string, fullMonth?: boolean) => {
  if (!date) {
    return '';
  }
  // Hack to convert date-time to exact date
  const convertedDate = new Date(`${date}T15:00:00`);
  const monthNumber = getMonth(convertedDate);
  const monthName = months[monthNumber];
  const year = getYear(convertedDate);
  return fullMonth ? `${monthName} ${year}` : `${monthName.slice(0, 3)} ${year}`;
};

/**
 * Formats a date string or a Date object as "Month Day" with
 * the month name abbreviated (e.g., "Oct 19").
 *
 * @param {string|Date} date - The date string or Date object to be formatted.
 * @returns {string} - The formatted date string.
 *
 * @example
 * formatMonthAndDay('2023-04-09'); // Returns "Apr 9"
 * formatMonthAndDay(new Date('2023-04-09')); // Returns "Apr 9"
 */
const getMonthAndDay = (date: string | Date) => {
  if (!date) {
    return '';
  }

  const convertedDate = typeof date === 'string' ? new Date(`${date}T15:00:00Z`) : date;
  const formattedDate = format(convertedDate, 'MMM d');
  return formattedDate;
};

/**
 * Generates a new Date object by concatenating the `date` and `time` parameters.
 *
 * @param {string|undefined} date - The date string to use in generating the new Date object.
 * @param {string|undefined} time - The time string to concatenate with the `date` parameter.
 * @returns {Date|string} - A new Date object representing the combined date and time,
 *                          or an empty string if `date` is falsy.
 */
const generateDateTime = (date?: string, time?: string) => {
  if (!date) return '';

  const parsedTime = time?.replace('+00', '');

  return new Date(`${date} ${parsedTime}`);
};

/**
 * Gets the time as a number from the given date and time string.
 *
 * @param {string|undefined} date - The date string to extract the time from.
 * @param {string|undefined} time - The time string to extract the time from.
 * @returns {number} - The time as a number from the given date and time string,
 *                     or 0 if both `date` and `time` are falsy.
 */
const getTimeAsNumber = (date?: string, time?: string) => {
  if (!date && !time) return 0;

  const dateTime = generateDateTime(date, time);

  return dateTime ? dateTime.getTime() : 0;
};

export {
  getBookedDate,
  generateDateTime,
  getDateWithDashes,
  getDateWithSlashes,
  getDetailedDate,
  getDetailedDateWithDayOfWeek,
  getDetailedDateWithDayOfWeekShort,
  getMonthAndDay,
  getMonthAndYear,
  getTimeAsNumber,
  parseMilitaryTime,
};
