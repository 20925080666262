type CarouselPropertiesType = {
  SPEED_SLOW: number;
  SPEED_FAST: number;
  SPEED_FASTER: number;
  SPEED_DEFAULT: number;
  DELAY: number;
  SPACE_BETWEEN: number;
};

const CAROUSEL_PROPERTIES: CarouselPropertiesType = {
  SPEED_SLOW: 5000,
  SPEED_FAST: 200,
  SPEED_FASTER: 100,
  SPEED_DEFAULT: 500,
  DELAY: 2000,
  SPACE_BETWEEN: 15,
};

export default CAROUSEL_PROPERTIES;
