/* eslint-disable @typescript-eslint/indent */
import React, { MouseEventHandler } from 'react';
import Image from 'next/image';
import arrowDown from '@assets/images/arrow-down-white-2.svg';
import arrowDownBlack from '@assets/images/arrow-down-bold-black.svg';
import profile from '@assets/images/user-profile.svg';
import profileWhite from '@assets/images/user-profile-white.svg';
import { useCookies } from 'react-cookie';
import User from '@customTypes/user';

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  classes?: string;
  isDisabled?: boolean;
  id?: string;
  isFixedHeader?: boolean;
  isLoginMenuOpen?: boolean;
};

const defaultProps = {
  classes: '',
  isDisabled: false,
  id: '',
  isFixedHeader: false,
  isLoginMenuOpen: false,
};

export default function ButtonLoginTransparent({
  onClick,
  classes,
  isDisabled,
  id,
  isFixedHeader,
  isLoginMenuOpen,
}: Props) {
  const baseStyles = `w-full flex justify-center items-center py-3 rounded-l-full 
rounded-r-full font-rp-pn-semi-bold tracking-wide border px-3 cursor-pointer h-10 d:border-2 d:h-12 d:px-7 ${
    isFixedHeader ? 'text-rp-primary-black border-rp-primary-black hover:bg-rp-light-gray-4' : ''
  }`;
  const [cookies] = useCookies();
  const user: User = cookies.userInformation;

  return (
    <button
      type="button"
      className={`${classes} ${baseStyles} ${
        isDisabled ? 'pointer-events-none opacity-50' : 'pointer-events-auto opacity-100'
      }`}
      onClick={onClick}
      id={id}
    >
      {user ? (
        <div className="relative mr-2 mt-1">
          <Image
            src={isFixedHeader || isLoginMenuOpen ? profile : profileWhite}
            alt="arrow up"
            width={16}
            height={16}
            className="login-header"
          />
        </div>
      ) : (
        <p className="mr-2 text-sm login-header d:text-base">Login</p>
      )}
      {isLoginMenuOpen ? (
        <div className="transform rotate-180 mb-1.5">
          <Image
            src={arrowDownBlack}
            alt="arrow up"
            width={13}
            height={13}
            className="login-header"
          />
        </div>
      ) : (
        <Image
          src={isFixedHeader ? arrowDownBlack : arrowDown}
          alt="arrow down"
          width={13}
          height={13}
          className="login-header"
        />
      )}
    </button>
  );
}

ButtonLoginTransparent.defaultProps = defaultProps;
