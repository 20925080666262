const DEFAULT_OPTIONS_POPULAR_TEXT = 'Popular';
const DEFAULT_OPTIONS_CURRENT_LOCATION_VALUE = 'current_location';

const SEARCH_DEFAULT_LOCATION_NAMES = [
  'Phoenix, AZ',
  'Miami, FL',
  'Orlando, FL',
  'West Hollywood, CA',
  'Austin, TX',
  'Honolulu, HI',
  'Fort Lauderdale, FL',
  'Palm Desert, CA',
];

const SEARCH_DEFAULT_OPTIONS = [
  { value: DEFAULT_OPTIONS_POPULAR_TEXT, label: DEFAULT_OPTIONS_POPULAR_TEXT },
  { value: 'Phoenix, AZ', label: 'Phoenix, AZ' },
  { value: 'Miami, FL', label: 'Miami, FL' },
  { value: 'Orlando, FL', label: 'Orlando, FL' },
  { value: 'West Hollywood, CA', label: 'West Hollywood, CA' },
  { value: 'Austin, TX', label: 'Austin, TX' },
  { value: 'Honolulu, HI', label: 'Honolulu, HI' },
  { value: 'Fort Lauderdale, FL', label: 'Fort Lauderdale, FL' },
  { value: 'Palm Desert, CA', label: 'Palm Desert, CA' },
];

const SEARCH_DEFAULT_LOCATIONS = [
  {
    id: 12,
    name: 'Phoenix, AZ',
    type: 'city',
    detailed_type: 'city_alias',
    url: '/hotel-day-passes/Phoenix-12',
    parent_id: 7,
    parent_type: 'state',
    indexName: 'staging_locations',
    objectID: 'Phoenix, AZ',
    queryID: '0495ae366c1bcec7a79c472e70662f7d',
  },
  {
    id: 14,
    name: 'Miami, FL',
    type: 'city',
    detailed_type: 'city',
    url: '/hotel-day-passes/Miami-14',
    parent_id: 6,
    parent_type: 'state',
    indexName: 'staging_locations',
    objectID: 'Miami, FL',
    queryID: '0495ae366c1bcec7a79c472e70662f7d',
  },
  {
    id: 36,
    name: 'Orlando, FL',
    type: 'city',
    detailed_type: 'city',
    url: '/hotel-day-passes/Orlando-36',
    parent_id: 6,
    parent_type: 'state',
    indexName: 'staging_locations',
    objectID: 'Orlando, FL',
    queryID: '0495ae366c1bcec7a79c472e70662f7d',
  },
  {
    id: 4,
    name: 'West Hollywood, CA',
    type: 'city',
    detailed_type: 'city',
    url: '/hotel-day-passes/West-Hollywood-4',
    parent_id: 4,
    parent_type: 'state',
    indexName: 'staging_locations',
    objectID: 'West Hollywood, CA',
    queryID: '0495ae366c1bcec7a79c472e70662f7d',
  },
  {
    id: 23,
    name: 'Austin, TX',
    type: 'city',
    detailed_type: 'city',
    url: '/hotel-day-passes/Austin-23',
    parent_id: 8,
    parent_type: 'state',
    indexName: 'staging_locations',
    objectID: 'Austin, TX',
    queryID: '0495ae366c1bcec7a79c472e70662f7d',
  },
  {
    id: 35,
    name: 'Honolulu, HI',
    type: 'city',
    detailed_type: 'city',
    url: '/hotel-day-passes/Honolulu-35',
    parent_id: 9,
    parent_type: 'state',
    indexName: 'staging_locations',
    objectID: 'Honolulu, HI',
    queryID: '0495ae366c1bcec7a79c472e70662f7d',
  },
  {
    id: 417,
    name: 'Fort Lauderdale, FL',
    type: 'city',
    detailed_type: 'city',
    url: '/hotel-day-passes/Fort-Lawn-417',
    parent_id: 39,
    parent_type: 'state',
    indexName: 'staging_locations',
    objectID: 'Fort Lauderdale, FL',
    queryID: '0495ae366c1bcec7a79c472e70662f7d',
  },
  {
    id: 10,
    name: 'Palm Desert, CA',
    type: 'city',
    detailed_type: 'city',
    url: '/hotel-day-passes/Palm-Desert-10',
    parent_id: 4,
    parent_type: 'state',
    indexName: 'staging_locations',
    objectID: 'Palm Desert, CA',
    queryID: '0495ae366c1bcec7a79c472e70662f7d',
  },
];

export {
  SEARCH_DEFAULT_LOCATIONS,
  DEFAULT_OPTIONS_POPULAR_TEXT,
  DEFAULT_OPTIONS_CURRENT_LOCATION_VALUE,
  SEARCH_DEFAULT_LOCATION_NAMES,
  SEARCH_DEFAULT_OPTIONS,
};
