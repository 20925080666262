import actionTypes from '@context/actionTypes';
import { UserAction } from '@customTypes/context';
import Product from '@customTypes/product';
import {
  SmartCalendarAvailability,
  SmartCalendarTimeGroupAvailability,
} from '@customTypes/smart-calendar';
import { groupBy } from 'lodash';
import { Dispatch } from 'react';

/**
 * It groups all the children products from the response in their corresponding date
 * and then returns the available inventory of each day.
 * required for time slotted product, since api response is different from regular products.
 * @param availability SmartCalendarAvailability
 * @returns object { product_inventory }
 */
const getInventoryGroupedByDate = (
  availability: SmartCalendarTimeGroupAvailability,
): SmartCalendarAvailability => {
  const groupedTimes = groupBy(Object.values(availability).flat(), 'date');

  const inventory = Object.entries(groupedTimes).map((entry) => {
    const [key, value] = entry;
    const quantity = value.reduce(
      (accumulator, currentValue) => currentValue.quantity + accumulator,
      0,
    );

    return {
      date: key,
      quantity,
    };
  });

  return {
    product_inventory: inventory,
  };
};

const openDatePickerWithFilterApplied = (
  product: Product,
  dispatch: Dispatch<UserAction>,
  hideOtherOptions: boolean = false,
) => {
  dispatch({
    type: actionTypes.OPEN_DATE_PICKER,
    payload: true,
  });

  dispatch({
    type: actionTypes.SET_SMART_CALENDAR_PRODUCT_FILTER,
    payload: {
      label: product.name,
      value: product.product_time_group_id || product.product_id.toString(),
      hideOtherOptions,
    },
  });
};

export { getInventoryGroupedByDate, openDatePickerWithFilterApplied };
