import { Cookies } from 'react-cookie';
import { AmplitudeCookie } from '@amplitude/experiment-node-server';

// ---------------------------------------------------------------------
// getAmplitudeSession -------------------------------------------------
// ---------------------------------------------------------------------
// This extracts the amplitude session id out from the query parameters.
//
// The session id in the query parameters is set by the NextJS middleware
// that parses the session id out of the cookie.
// ---------------------------------------------------------------------
export function getAmplitudeSession(): number | undefined {
  const urlParams = new URLSearchParams(document.location.search);
  const sessionID = urlParams.get('amp_session_id');
  if (sessionID) return +sessionID;
  return undefined;
}

// ---------------------------------------------------------------------
// setAmplitudeSession -------------------------------------------------
// ---------------------------------------------------------------------
// Used to persist the amplitude session id inside a cookie.
// This session id is used to track amplitude sessions across
// sandboxed webviews.
//
// eg: Facebook in-app webview -> external browser
// ---------------------------------------------------------------------
export function setAmplitudeSession(sessionID: number) {
  const cookies = new Cookies();
  cookies.set('amp_session_id', sessionID.toString(), {
    path: '/',
  });
}

// ---------------------------------------------------------------------
// getAmplitudeDeviceID ------------------------------------------------
// ---------------------------------------------------------------------
// This extracts the amplitude device id out from the query parameters.
//
// The device id in the query parameters is set by the NextJS middleware
// that parses the device id out of the cookie.
// ---------------------------------------------------------------------
export function getAmplitudeDeviceID(): string | undefined {
  const cookies = new Cookies();
  const amplitudeDeviceIdCookie = cookies.get('amp_device_id');
  let parsedCookie = null;
  if (amplitudeDeviceIdCookie) {
    parsedCookie = AmplitudeCookie.parse(amplitudeDeviceIdCookie)?.device_id;
  }
  const urlParams = new URLSearchParams(document.location.search);
  const deviceID = urlParams.get('adid');
  return deviceID || parsedCookie || undefined;
}

// ---------------------------------------------------------------------
// setAmplitudeDeviceID -------------------------------------------------
// ---------------------------------------------------------------------
// Used to persist the amplitude device id inside a cookie.
// This device id is used to track the device across
// sandboxed webviews.
//
// eg: Facebook in-app webview -> external browser
// ---------------------------------------------------------------------
export function setAmplitudeDeviceID(deviceID: string) {
  const cookies = new Cookies();
  if (cookies.get('amp_device_id')) return;
  cookies.set('amp_device_id', deviceID, {
    path: '/',
  });
}
