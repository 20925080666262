type MappingObject = {
  'Day pass': number;
  Daybed: number;
  Cabana: number;
  'Pool Chair': number;
  'Family Pass': number;
  'Work Pass': number;
  'Day Room': number;
  'Meeting Room': number;
  'Spa Pass': number;
};

const productSortMap: MappingObject = {
  'Day pass': 1,
  'Pool Chair': 2,
  'Family Pass': 3,
  Cabana: 4,
  Daybed: 5,
  'Spa Pass': 6,
  'Work Pass': 7,
  'Day Room': 8,
  'Meeting Room': 9,
};

export { productSortMap };
export type { MappingObject };
