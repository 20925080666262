import { useState, useCallback, useEffect } from 'react';

export default function useLocalStorage(key: string, defaultValue: any) {
  const [val, setVal] = useState(defaultValue);

  useEffect(() => {
    const lVal = window.localStorage.getItem(key);
    if (lVal) {
      setVal(JSON.parse(lVal));
    } else {
      window.localStorage.setItem(key, JSON.stringify(defaultValue));
      setVal(defaultValue);
    }
  }, [key, defaultValue]);

  const setLocalStorageVal = useCallback(
    (value: any) => {
      if (window) {
        window.localStorage.setItem(key, JSON.stringify(value));
        setVal(value);
      }
    },
    [key],
  );

  return [val, setLocalStorageVal];
}
