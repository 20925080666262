/* eslint-disable @typescript-eslint/naming-convention */
import sessionStorageKeys from '@constants/sessionStorageKeys';
import { ContextState } from '@customTypes/context';

const convertCurrency = (value: number | string, state: ContextState, precision: number = 0) => {
  const { selectedCurrency } = state;
  const { symbol, exchange_rate } = selectedCurrency;
  const exchangeRate = exchange_rate ?? 1;
  const valueWithPrecision = (+value * exchangeRate).toFixed(precision);
  // uncomment line below if comma separated values are needed
  // const finalValue = Number(valueWithPrecision).toLocaleString();
  const finalValue = valueWithPrecision;
  return `${symbol}${finalValue}`;
};

const showIsoCode = (state: ContextState, getItem: (key: string) => string) => {
  const { selectedCurrency } = state;
  const { symbol, iso_code } = selectedCurrency;
  let isShowIsoCode = symbol === '$' && iso_code !== 'USD';

  if (getItem) {
    const userFromUsaSessionItem = getItem(sessionStorageKeys.USER_FROM_USA);
    if (userFromUsaSessionItem) {
      const userFromUsa = userFromUsaSessionItem === 'true';
      if (!userFromUsa) {
        isShowIsoCode = symbol === '$';
      }
    }
  }

  return isShowIsoCode;
};

export { convertCurrency, showIsoCode };
