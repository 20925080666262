/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-confusing-arrow */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/no-unstable-nested-components */
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import Image from 'next/image';
import { components, SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import SearchIcon from '@common/SearchIcon';
import SearchHeaderIcon from '@common/SearchHeaderIcon';
import locationIcon from '@assets/images/location-icon.svg';
import hotelIcon from '@assets/images/hotel-icon.svg';
import stateIcon from '@assets/images/state-icon.svg';
import countryIcon from '@assets/images/country-icon.svg';
import popularIcon from '@assets/images/popular-icon.svg';
import locationTargetIcon from '@assets/images/location-target-icon.svg';
import { getLocations, trackAlgoliaEvent } from '@utils/services';
import { Location } from '@components/Hotels/types';
import { debounce, findKey } from 'lodash';
import useSessionStorage from '@hooks/useSessionStorage';
import sessionStorageKeys from '@constants/sessionStorageKeys';
import { useRouter } from 'next/router';
import {
  popularLocationClicked,
  preSelectedLocationClicked,
  zipCodeToolTipViewed,
} from '@events/globals';
import { useCookies } from 'react-cookie';
import { store } from '@context/store';
import {
  SEARCH_DEFAULT_LOCATIONS,
  DEFAULT_OPTIONS_POPULAR_TEXT,
  DEFAULT_OPTIONS_CURRENT_LOCATION_VALUE,
  SEARCH_DEFAULT_LOCATION_NAMES,
  SEARCH_DEFAULT_OPTIONS,
} from '@constants/SearchDefaultOptions';
import { SEARCH, SELECT_SEARCH_LOCATION, START_SELECT_LOCATION } from '@constants/amplitudeEvents';
import { useEvents } from '@events/EventsProvider';
import useAmplitudePayloads from '@hooks/useAmplitudePayloads';
import { getDateWithDashes } from '@helpers/dateFormatter';
import actionTypes from '@context/actionTypes';

const ERROR_TEXT = `<span style="font-family: proxima-nova-semi-bold; color: #000; font-size:17px;">Looks like you are searching a zip code.</span><br/>
  <span style="font-family: proxima-nova-regular; color: #000; font-size:15px;">Please enter a city, state or hotel name to get the best results. Your daycation awaits.</span>`;

interface SearchOption {
  readonly value: string;
  readonly label: string;
}

type Action = {
  action: string;
};

type Props = {
  value: SingleValue<SearchOption> | undefined;
  setValue: Function;
  locations: Location[];
  setLocations: Dispatch<SetStateAction<Location[]>>;
  searchWithoutQuery: boolean;
  headerVariant?: boolean;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  input?: string;
  setSearchOptions?: Dispatch<SetStateAction<SearchOption[]>>;
  isBottomSearchBar?: boolean;
  isShowLocationNearMe?: boolean;
  isNewHomePage?: boolean;
  scrollIntoView?: boolean;
};

type SelectedValue = { value: string; label: string; type: string };

const defaultProps = {
  headerVariant: false,
  input: '',
  setIsLoading: () => null,
  setSearchOptions: undefined,
  isBottomSearchBar: false,
  isShowLocationNearMe: false,
  isNewHomePage: false,
  scrollIntoView: false,
};

function Input(props: any) {
  const instanceId = useMemo(
    () => `typeahead-search-input-${Math.ceil(Math.random() * 10)}-input`,
    [],
  );

  return (
    <components.Input
      {...props}
      isHidden={false}
      className=""
      aria-label="City, state, or hotel"
      aria-controls={instanceId}
      id={instanceId}
    />
  );
}

function IconOption(props: any) {
  const { data, selectProps }: { data: any; selectProps: any } = props;
  const isFrozen = data.label === DEFAULT_OPTIONS_POPULAR_TEXT;
  const noIcon = SEARCH_DEFAULT_LOCATION_NAMES.indexOf(data.value) > -1;
  const currentLocation = data.value === DEFAULT_OPTIONS_CURRENT_LOCATION_VALUE;
  const clearQuery = selectProps.inputValue.replace(/[\\[.+*?(){|^$]/g, '\\$&');
  const regExp = new RegExp(clearQuery, 'gi');
  let { label } = data;
  if (noIcon || currentLocation) {
    label = data.label.replace(
      data.label,
      (str: string) =>
        `<span style="font-family: proxima-nova-regular; color: #000;">${str}</span>`,
    );
  } else {
    label = data.label.replace(
      regExp,
      (str: string) =>
        `<span style="font-family: proxima-nova-semi-bold; color: #000;">${str}</span>`,
    );
  }
  let icon = locationIcon;
  if (currentLocation) {
    icon = locationTargetIcon;
  } else if (data.type === 'hotel') {
    icon = hotelIcon;
  } else if (data.type === 'state') {
    icon = stateIcon;
  } else if (data.type === 'country') {
    icon = countryIcon;
  } else if (data.type === 'zip') {
    icon = null;
  } else if (isFrozen) {
    icon = popularIcon;
  }

  const iconClass = `w-10 relative ${
    icon === countryIcon
      ? '-ml-1 mr-0.5'
      : noIcon
      ? 'invisible'
      : icon === locationTargetIcon
      ? 'mt-0.5'
      : '-ml-px'
  }`;

  return (
    <components.Option
      {...props}
      className={`${
        isFrozen ? 'frozen ' : noIcon ? 'no-icon ' : currentLocation ? 'current-location ' : ''
      }needsclick`}
    >
      <div className="flex">
        {icon && (
          <div className={iconClass}>
            <Image src={icon} alt="location-icon" />
          </div>
        )}
        {/* eslint-disable-next-line react/no-danger */}
        <div className={`${isFrozen ? 'faded' : ''}`} dangerouslySetInnerHTML={{ __html: label }} />
      </div>
    </components.Option>
  );
}

function MenuListComponent(props: any) {
  const { children, onReset, getValue } = props;
  const value = getValue();
  const isZipCode =
    (value && value[0] && value[0].label === '') || (value && value.length === 0)
      ? false
      : !Number.isNaN(Number(value[0].label));
  return (
    <components.MenuList {...props}>
      <div className="relative max-h-80">
        <div className={`${isZipCode ? 'zip' : 'pb-0'}`}>{children}</div>
        {onReset && value && value.length > 0 && (
          <button
            type="button"
            onClick={onReset}
            className="text-sm capitalize pl-3 text-rp-primary-dark py-3 font-rp-pn-semi-bold inline-block sticky bottom-0 bg-white w-full text-right pr-7 border-t border-rp-gray-divider d:font-semibold d:text-lg d:-mb-1"
          >
            SEE ALL HOTELS {' >'}
          </button>
        )}
      </div>
    </components.MenuList>
  );
}

export default function ReactSelectSearch({
  headerVariant,
  value,
  setValue,
  searchWithoutQuery,
  locations,
  setLocations,
  setIsLoading,
  input,
  setSearchOptions,
  isBottomSearchBar,
  isShowLocationNearMe,
  isNewHomePage,
  scrollIntoView,
}: Props) {
  const [inputValue, setInputValue] = useState<string>();
  const [documentObject, setDocumentObject] = React.useState<Document>();
  const [wasZipCodeTooltipViewed, setWasZipCodeTooltipViewed] = React.useState<boolean>(false);
  const [defaultOptions, setDefaultOptions] = React.useState<SearchOption[]>([]);
  const [cityOption, setCityOption] = React.useState<SearchOption>();
  const [cityResponse, setCityResponse] = React.useState<Location>();
  const [searchInputCleared, setSearchInputCleared] = useState<boolean>(false);
  const [startSelectSearchEventFired, setStartSelectSearchEventFired] = useState<boolean>(false);
  const [disableTextSelect, setDisableTextSelect] = React.useState<boolean>(false);
  const inputRef = useRef<any>();
  const { getItem, setItem, removeItem } = useSessionStorage();
  const sessionID = getItem(sessionStorageKeys.SESSION_ID);
  const { track } = useEvents();
  const { generateSearchLocationPayload } = useAmplitudePayloads();
  const [cookies] = useCookies();
  const router = useRouter();
  const { date, cityName } = router.query;
  const globalState = useContext(store);
  const {
    dispatch,
    state: { userCityAndState, searchDate },
  } = globalState;

  const user = cookies.userInformation;
  const userID = user ? user.id : sessionID;

  React.useEffect(() => {
    setDocumentObject(document); // Hack needed for NextJS
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptions = useCallback(
    debounce((inputString: string, callback: any) => {
      if (inputString) {
        setDefaultOptions([]);

        if (setIsLoading) {
          setIsLoading(true);
        }
        if (inputString === '%') {
          // eslint-disable-next-line no-param-reassign
          inputString = '%25';
        }
        getLocations(`"${inputString}"`).then((response: Location[]) => {
          const allOptions = response.map((l) => ({
            value: l.objectID,
            label: l.name,
            type: l.type,
          }));
          setLocations(response);
          if (
            inputString.length >= 2 &&
            !Number.isNaN(Number(inputString)) &&
            allOptions.length === 0
          ) {
            allOptions.push({ label: ERROR_TEXT, value: 'error', type: 'zip' });
          }

          if (setSearchOptions) setSearchOptions(allOptions);
          callback(allOptions);
          if (setIsLoading) {
            setIsLoading(false);
          }
        });
      }
    }, 275),
    [setIsLoading, setLocations],
  );

  const loadOptionsNew = useCallback(
    async (inputString: string) => {
      if (inputString) {
        setDefaultOptions([]);
        if (setIsLoading) {
          setIsLoading(true);
        }
        if (inputString === '%') {
          // eslint-disable-next-line no-param-reassign
          inputString = '%25';
        }
        const response = await getLocations(`"${inputString}"`);
        const allOptions = response.map((l) => ({ value: l.objectID, label: l.name }));
        setLocations(response);
        if (setIsLoading) {
          setIsLoading(false);
        }
        if (
          inputString.length >= 2 &&
          !Number.isNaN(Number(inputString)) &&
          allOptions.length === 0
        ) {
          allOptions.push({ label: ERROR_TEXT, value: 'error' });
        }
        return allOptions;
      }
      return [];
    },
    [setIsLoading, setLocations],
  );

  React.useEffect(() => {
    if (input && !date && !cityName) {
      setInputValue(input);
      loadOptionsNew(input);
    }
  }, [cityName, date, input, loadOptionsNew]);

  React.useEffect(() => {
    if (
      inputValue &&
      inputValue?.length >= 2 &&
      !Number.isNaN(Number(inputValue)) &&
      !wasZipCodeTooltipViewed
    ) {
      // Event
      zipCodeToolTipViewed();
      setWasZipCodeTooltipViewed(true);
    }
  }, [inputValue, wasZipCodeTooltipViewed]);

  React.useEffect(() => {
    (async () => {
      if (!searchWithoutQuery && router.isReady) {
        const storedLocationString = getItem(sessionStorageKeys.SEARCHED_LOCATION);
        let valueSet = false;
        // auto select value from session storage on home page
        if (storedLocationString) {
          const storedLocationObj = JSON.parse(storedLocationString);

          dispatch({
            type: actionTypes.UPDATE_SEARCHED_LOCATION,
            payload: storedLocationObj,
          });

          const { name } = storedLocationObj;
          const path = router.pathname;
          if (path === '/') {
            const valueObj = {
              value: name,
              label: name,
            };
            setInputValue(name);
            setValue(valueObj);
            valueSet = true;
            if (SEARCH_DEFAULT_LOCATION_NAMES.indexOf(name) > -1) {
              setLocations(SEARCH_DEFAULT_LOCATIONS);
            } else {
              loadOptionsNew(name);
            }
          }
        }
        // auto populating user city
        if (userCityAndState && isShowLocationNearMe) {
          const response = await getLocations(`"${userCityAndState}"`);

          dispatch({
            type: actionTypes.SET_USER_LOCATION,
            payload: response[0],
          });

          const allOptions = response.map((l) => ({ value: l.objectID, label: l.name }));
          if (allOptions && allOptions.length > 0) {
            const firstOption = allOptions[0];
            setCityOption({
              label: firstOption.label,
              value: DEFAULT_OPTIONS_CURRENT_LOCATION_VALUE,
            });
            setCityResponse(response[0]);
            if (!valueSet) {
              setInputValue(firstOption.value);
              setValue(firstOption);
              setLocations(response);
            }
          }
        }
      } else {
        setInputValue('');
        setValue({ value: '', label: '' });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWithoutQuery, setValue, userCityAndState]);

  const updateDefaultOptions = useCallback(() => {
    const allOptions = [...SEARCH_DEFAULT_OPTIONS];
    const allLocations: Location[] = [...SEARCH_DEFAULT_LOCATIONS];
    if (cityOption) {
      allOptions.unshift(cityOption);
    }
    if (cityResponse) {
      allLocations.unshift(cityResponse);
    }
    setDefaultOptions(allOptions);
    setLocations(allLocations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityOption, cityResponse]);

  const handleInputChange = (newValue: string, { action }: Action) => {
    if (action === 'input-change') {
      // Check if the field was cleared and user starts typing the first word
      if (!startSelectSearchEventFired) {
        if (!searchInputCleared && newValue.trim() === '') {
          setSearchInputCleared(true);
        } else if (searchInputCleared && newValue.trim() !== '') {
          // User starts typing the first word, trigger the event.
          track(START_SELECT_LOCATION, {});
          setStartSelectSearchEventFired(true);
          setSearchInputCleared(false);
        }
      }
      setInputValue(newValue);
      if (!newValue && isShowLocationNearMe) {
        updateDefaultOptions();
      }
    }
  };

  const trackAlgoliaInsightEvent = (search: SelectedValue | undefined) => {
    if (!search) return;

    if (locations) {
      const selectedLocationValue = locations.filter(
        (location: Location) => location.name === search.value,
      )[0];

      if (selectedLocationValue) {
        const position = findKey(locations, selectedLocationValue) || 0;
        trackAlgoliaEvent({
          event_type: 'click',
          index_name: selectedLocationValue.indexName || '',
          object_id: selectedLocationValue.objectID,
          position: (+position + 1).toString(),
          query_id: selectedLocationValue.queryID,
          user_token: userID,
        });

        setItem(
          sessionStorageKeys.ALGOLIA_TYPE_AHEAD,
          JSON.stringify({
            type_ahead_query_id: selectedLocationValue.queryID,
            type_ahead_index: selectedLocationValue.indexName,
          }),
        );
      }
    }
  };

  const handleChange = (newValue: any) => {
    const finalValue = { ...newValue };
    if (finalValue && finalValue.value) {
      if (SEARCH_DEFAULT_LOCATION_NAMES.indexOf(finalValue.value) > -1) {
        popularLocationClicked(finalValue.label);
      } else if (finalValue.value === DEFAULT_OPTIONS_CURRENT_LOCATION_VALUE) {
        finalValue.value = finalValue.label;
        preSelectedLocationClicked(finalValue.label);
      }
    }

    setInputValue(finalValue ? finalValue.label : '');
    setValue(finalValue);

    const selectedLocation = locations.find(
      (location) => location.type === finalValue.type && location.name === finalValue.value,
    );

    if (selectedLocation) {
      dispatch({
        type: actionTypes.UPDATE_SEARCHED_LOCATION,
        payload: selectedLocation,
      });

      const searchPropertyGroup = generateSearchLocationPayload(selectedLocation);

      track(SELECT_SEARCH_LOCATION, searchPropertyGroup);

      if (router.pathname !== '/') {
        track(
          SEARCH,
          {
            search_date: searchDate ? getDateWithDashes(searchDate) : null,
            ...searchPropertyGroup,
          },
          { withURLandTitle: true },
        );
      }

      setStartSelectSearchEventFired(false);
    }

    setDisableTextSelect(false);

    trackAlgoliaInsightEvent(finalValue);

    if (headerVariant) {
      removeItem(sessionStorageKeys.SRP_FILTERS);
      removeItem(sessionStorageKeys.SHOW_ONLY_AVAILABLE_FILTER);
    }
  };

  const navigateToBrowseHotels = () => {
    router.push('/browse-hotels');
  };

  const onMouseUp = (event: any) => {
    if (
      !disableTextSelect &&
      isShowLocationNearMe &&
      (!headerVariant || isNewHomePage) &&
      inputRef.current
    ) {
      event.preventDefault();
      inputRef.current.inputRef.select();
      setDisableTextSelect(true);
      updateDefaultOptions();
    }
  };

  const onBlur = (event: any) => {
    if ((!headerVariant || isNewHomePage) && isShowLocationNearMe) {
      event.preventDefault();
      setDisableTextSelect(false);
    }
  };

  const instanceId = useMemo(() => `react-select-${Math.ceil(Math.random() * 10)}-input`, []);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={`${
        headerVariant && isShowLocationNearMe
          ? 'react-select-srp-sticky'
          : headerVariant
          ? 'react-select-srp'
          : isNewHomePage
          ? 'react-select-component-home'
          : 'react-select-component'
      }`}
      onMouseUp={onMouseUp}
      onPointerUp={onMouseUp}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={instanceId} className="sr-only">
        Search city, state, or hotel
      </label>
      <AsyncSelect
        ref={inputRef}
        id={instanceId}
        inputId={instanceId}
        instanceId={instanceId}
        className={headerVariant ? 'react-select-container-header' : 'react-select-container'}
        classNamePrefix={
          headerVariant && isShowLocationNearMe
            ? 'react-select-options-srp-sticky'
            : headerVariant
            ? 'react-select-options-srp'
            : isNewHomePage
            ? 'react-select-options-home'
            : `react-select-options${isBottomSearchBar ? '-bottom' : ''}`
        }
        value={value}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleChange}
        placeholder="City, state, or hotel"
        loadOptions={loadOptions}
        defaultOptions={defaultOptions}
        components={{
          ValueContainer: headerVariant ? SearchHeaderIcon : SearchIcon,
          Input,
          Option: IconOption,
          MenuList: (props) => <MenuListComponent {...props} onReset={navigateToBrowseHotels} />,
        }}
        controlShouldRenderValue={false}
        isMulti={false}
        backspaceRemovesValue
        isClearable
        noOptionsMessage={() => null}
        blurInputOnSelect
        onBlur={onBlur}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          control: (base) => ({ ...base, ...(headerVariant && { height: '100% !important' }) }),
          container: (base) => (headerVariant ? { height: '100% !important' } : base),
          // eslint-disable-next-line no-confusing-arrow
          placeholder: (base) =>
            headerVariant
              ? {
                  fontFamily: 'Proxima Nova',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '15px !important',
                  lineHeight: '18px',
                  color: '#252525',
                }
              : base,
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            if (inputValue && inputValue?.length >= 2 && !Number.isNaN(Number(inputValue))) {
              e.preventDefault();
              e.stopPropagation();
            }
          }
        }}
        menuPortalTarget={documentObject?.body}
      />
      {scrollIntoView && <div className="h-20 w-20 -mb-24" id="search-bar-scroll-helper" />}
    </div>
  );
}

ReactSelectSearch.defaultProps = defaultProps;
